import { apiConstants } from '../_constants';
import { authHeader, handleResponse } from '../_helpers';
import { jsonToQueryString } from '../_helpers/utilFunctions';

export const organizationService = {
  organizationAdd,
  organizationList,
  organizationDelete,
  organizationGet,
  organizationDeactivate,
  makeDefaultOrganization,
  getOrganizationDetails,
};

async function organizationAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/company_add', requestOptions).then(handleResponse);
}

async function organizationList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(apiConstants.apiUrl + '/company_list', requestOptions).then(handleResponse);
}

async function organizationGet(data) {
  const params = jsonToQueryString(data);
  const apiUrl = `${apiConstants.apiUrl}/get_company?${params}`;
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(apiUrl, requestOptions).then(handleResponse);
}

// async function getOrganizationDetails(data) {
//   console.log(data, 'checkkkk');

//   const apiUrl = `${apiConstants.apiUrl}/get_company_details`;
//   const requestOptions = {
//     method: 'GET',
//     headers: authHeader(),
//     body: JSON.stringify(data),
//   };
//   return fetch(apiUrl, requestOptions).then(handleResponse);
// }

async function getOrganizationDetails(data) {
  const params = jsonToQueryString(data);
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`${apiConstants.apiUrl}/get_company_details?${params}`, requestOptions).then(
    handleResponse,
  );
}

async function organizationDelete(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/company_delete', requestOptions).then(handleResponse);
}

async function organizationDeactivate(data) {
  const apiUrl = `${apiConstants.apiUrl}/company_deactivate`;
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(apiUrl, requestOptions).then(handleResponse);
}

async function makeDefaultOrganization(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/make_default_company', requestOptions)
    .then(handleResponse)
    .then((user) => {
      // login successful if there's a jwt token in the response
      if (user.data.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user.data));
        localStorage.setItem('company_id', user.data.company_id);
      }

      return user;
    });
}
