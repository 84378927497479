/* eslint-disable no-unused-vars */
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Pen, Plus, Search, Trash } from 'lucide-react';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import { Images } from '../Utils';
import { Calendar, CompanyIcon, LoadingIcon, TodoCheckICon } from '../Utils/SvgIcons';
import { Button, Modal, PaginationComponent, TableRow } from '../_components';
import { ErrorComponent } from '../_components/ErrorComponent';
import ToolTip from '../_components/Tooltip/ToolTip';
import { RT } from '../_constants';
import { DELETE_NOTE } from '../_constants/deletenote.constants';
import useDebounce from '../_helpers/useDebounce';
import { organizationService } from '../_services/organization.service';
import { useUser } from '../Hook/useUser';

const TABLE_COLUMN = ['Sl.No', 'Name', 'Created At', 'Type', 'Action'];

export function OrganizationList() {
  // hooks section
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // reference section
  const reference = useRef(null);
  const tableContainerRef = useRef(null);

  const user=useUser();

  // useState section
  const [query, setQuery] = useState({
    select: ['id', 'name', 'createdAt', 'updatedAt',  'is_default'],
    searchKey: '',
    pageVo: {
      pageNo: parseInt(searchParams.get('pageNo')) || 1,
      noOfItems: 10,
    },
    // status: 1,
  });

  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editOrganizationId, setEditOrganizationId] = useState(null);

  const debouncedSearch = useDebounce(query?.searchKey, 500);

  // organization list API call section
  const {
    data: organizationData,
    error,
    isLoading,
    isSuccess,
    refetch,
  } = useQuery({
    queryKey: ['organizationList', query?.pageVo?.pageNo, debouncedSearch],
    queryFn: () => organizationService.organizationList(query),
    placeholderData: keepPreviousData,
  });

  const modalClose = () => {
    setDeleteModal(false);
    setEditOrganizationId(null);
  };

  // section trigger when delete option selected

  const handleDeactivate = async (id) => {
    try {
      await organizationService.organizationDeactivate({ id });
      let isNeedToBack =
        organizationData?.data?.rows.length === 1 && organizationData?.data?.pages > 1;
      if (isNeedToBack) {
        const page = searchParams.get('pageNo');
        searchParams.set('pageNo', page - 1);
        setSearchParams(searchParams);
        setQuery((prev) => ({
          ...prev,
          pageVo: {
            ...prev.pageVo,
            pageNo: prev.pageVo.pageNo - 1,
          },
        }));
      } else {
        refetch();
      }
      modalClose();
    } catch (error) {
      console.error('Error deleting organization:', error);
    }
  };

  // edit or delete function activation section

  const handleClickOption = (option) => {
    if (option == 'edit') {
      navigate(`/${RT.ORGANIZATION_ADD}/${editOrganizationId}`);
    } else if (option == 'delete') {
      setDeleteModal(true);
    }
  };

  // get the count of organization
  const listItemCount = organizationData?.data?.rows.length;

  const orgRows = organizationData?.data?.rows.map((org, index) => {
    const slNo = (organizationData?.data?.page - 1) * organizationData?.data?.limit + index + 1;
    return (
      <Tr key={index}>
        <Td>{slNo}</Td>

        <Td>{org?.name} </Td>
        <Td>{moment(org?.createdAt).format('MMM DD, YYYY')}</Td>
        <Td>{org?.id == user.company_id  ? 'Default' : ' '}</Td>

        {/* <Td
          style={{ verticalAlign: 'middle', cursor: 'pointer' }}
          ref={reference}
          onClick={(event) => {
            event.stopPropagation();
            setContextMenuVisible(true);
            setEditOrganizationId(org?.id);
          }}
        >
          <div className='ellipsis_icon'>
            <Ellipsis />
            {contextMenuVisible && editOrganizationId == org?.id && (
              <ContextMenu
                handleClick={(value) => {
                  handleClickOption(value);
                }}
                isContextVisible={true}
                onRequestClose={() => {
                  setContextMenuVisible(false);
                }}
              >
                {org?.is_default ? (
                  <div
                    className='menu_items'
                    onClick={(event) => {
                      event.stopPropagation();
                      handleClickOption(CONTEXT_MENU_OPTIONS.EDIT);
                    }}
                  >
                    Edit
                  </div>
                ) : (
                  ''
                )}
              </ContextMenu>
            )}
          </div>
        </Td> */}
        {/* {projectData?.data?.isPrivileged && ( */}
        <Td
          style={{ verticalAlign: 'middle', cursor: 'pointer' }}
          ref={reference}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div className='d-flex gap-2 justify-content-end icon-div invisible'>
            {/* {(projecTdata?.data?.isPrivileged || permissionsMap?.project_edit) && ( */}
            <ToolTip
              tooltipText={`Edit Organization`}
              isModern
              popOver
              scrollRef={tableContainerRef}
            >
              <div
                className='icon-bg'
                onClick={(event) => {
                  event.stopPropagation();
                  navigate(`/${RT.ORGANIZATION_ADD}/${org.id}`);
                }}
              >
                <Pen size={15} />
              </div>
            </ToolTip>
            {/* )} */}
            {!org?.is_default && (
              <ToolTip
                tooltipText={`Delete Organization`}
                isModern
                popOver
                scrollRef={tableContainerRef}
              >
                <div
                  className='icon-bg'
                  onClick={(event) => {
                    event.stopPropagation();
                    setEditOrganizationId(org?.id);
                    setDeleteModal(true);
                  }}
                >
                  <Trash size={15} />
                </div>
              </ToolTip>
            )}
          </div>
        </Td>
        {/* )} */}
      </Tr>
    );
  });

  // search box handleChange
  const handleSearch = (event) => {
    searchParams.set('pageNo', 1);
    setSearchParams(searchParams);
    let search = event.target.value;
    setQuery((prevQuery) => ({
      ...prevQuery,
      pageVo: { ...query.pageVo, pageNo: 1 },
      searchKey: search,
    }));
  };

  return (
    <div className='fade-entry'>
      <div>
        {/* component will active when error occurs   */}
        <ErrorComponent error={error?.message} />
        <div className='company-table-container mt-3'>
          <section>
            <div className='tab_contain'>
              <div className='search_contain'>
                <input
                  type='text'
                  className='bodr_none'
                  onChange={(event) => {
                    handleSearch(event);
                  }}
                  placeholder='Search...'
                />
                <Search size={18} color='#87909E' />
              </div>
              <div className='d-flex gap-1 align-items-center justify-content-end'>
                {/* {is_admin?.value && ( */}
                <ToolTip tooltipText={'Add Organization'} isModern popOver>
                  <div
                    className='menu-item-outline'
                    onClick={() => navigate(`/${RT.ORGANIZATION_ADD}/0`)}
                  >
                    <Plus size={16} color='#87909E' />
                  </div>
                </ToolTip>
                {/* )} */}
              </div>
            </div>
          </section>

          <section className='mt-2'>
            <div
              className='table-container no-padding'
              ref={tableContainerRef}
              style={{ maxHeight: '65vh' }}
            >
              <Table className='table table-hover tbl mb-0'>
                <Thead>
                  <Tr style={{ backgroundColor: '#F3F5F7' }} className='table-head'>
                    {TABLE_HEADINGS.map((heading, index) => {
                      return (
                        <Th
                          key={index}
                          scope='col'
                          style={{ minWidth: heading.minWidth, maxWidth: heading.maxWidth }}
                        >
                          {heading.name}
                        </Th>
                      );
                    })}
                    {/* </tr>
                </thead>
                <tbody>
                  {isLoading && (
                    <TableRow noOfCols={6}>
                      <LoadingIcon color='blue' size={60} />
                    </TableRow>
                  )}
                  {!listItemCount && isSuccess && <TableRow noOfCols={6}>No data found</TableRow>}
                  {listItemCount > 0 && isSuccess && orgRows}
                </tbody>
              </table> */}
                  </Tr>
                </Thead>
                <Tbody style={{ verticalAlign: 'middle' }}>
                  {isLoading && (
                    <TableRow noOfCols={5}>
                      <LoadingIcon color='blue' size={50} />
                    </TableRow>
                  )}
                  {!listItemCount && isSuccess && <TableRow noOfCols={8}>No data found</TableRow>}
                  {listItemCount > 0 && isSuccess && orgRows}
                </Tbody>
              </Table>
            </div>
          </section>
          {listItemCount > 0 && (
            <section className='mt-2'>
              <PaginationComponent
                pageOnClick={(data) => {
                  setQuery((prev) => ({
                    ...prev,
                    pageVo: {
                      ...prev.pageVo,
                      pageNo: data,
                    },
                  }));
                  searchParams.set('pageNo', data);
                  setSearchParams(searchParams);
                }}
                demoData={organizationData?.data}
              />
            </section>
          )}
        </div>
        {deleteModal && (
          <Modal.Container>
            <Modal.View className={'status-delete'}>
              <Modal.Head handleClose={() => modalClose()} Heading={'Delete Confirmation'} />
              <Modal.Description>
                <img src={Images.DeleteBin} width={35} height={50} alt='' className='mb-3' />
                <div className='del-description'>{DELETE_NOTE.ORGANIZATION}</div>
              </Modal.Description>
              <div className='status-del-btn'>
                <Button.Container handleOk={() => modalClose()} className={'status-del-cancel'}>
                  <Button.Title title={'Cancel'} />
                </Button.Container>
                <Button.Container handleOk={() => handleDeactivate(parseInt(editOrganizationId))}>
                  <Button.Title title={'Delete'} />
                </Button.Container>
              </div>
            </Modal.View>
          </Modal.Container>
        )}
      </div>
    </div>
  );
}

const TABLE_HEADINGS = [
  { name: <img src={Images.SerialNo} height={14} width={14} />, minWidth: 50 },
  {
    name: (
      <div className='d-flex align-items-center gap-1'>
        <CompanyIcon height={16} width={16} color='#87909E' /> Organization
      </div>
    ),
    minWidth: '10vw',
    maxWidth: '20vw',
  },

  {
    name: (
      <div className='d-flex align-items-center gap-1'>
        <Calendar height={14} width={14} />
        Created Date
      </div>
    ),
    minWidth: 50,
  },
  {
    name: (
      <div className='d-flex align-items-center gap-1'>
        {' '}
        <TodoCheckICon /> Type
      </div>
    ),
    minWidth: 50,
  },
  {},
];
