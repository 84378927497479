/* eslint-disable no-unused-vars */
import { useQueryClient } from '@tanstack/react-query';
import { ImageUp } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReactSelect from 'react-select';
import { phoneNumberValidation, validatePinNumber } from '../Utils';
import { OrganizationState } from '../Utils/States';
import { CustomFileUpload } from '../_components';
import { Button } from '../_components/CustomButton';
import { ErrorComponent } from '../_components/ErrorComponent';
import { alertConstants, apiConstants, CommonDropDownStyle } from '../_constants';
import { organizationService } from '../_services/organization.service';
export function OrganizationAdd() {
  const queryClient = useQueryClient();

  const { id } = useParams(); // Access the id parameter from the URL
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({ message: '' });
  const navigate = useNavigate();
  const [timeZoneList, setTimeZoneList] = useState([]);

  const [query, setQuery] = useState({
    select: [
      'id',
      'name',
      'address',
      'city',
      'pin_code',
      'phone',
      'email',
      'website',
      'logo',
      'company_time_zone',
      'open_time',
      'close_time',
    ],
  });
  const [organization, setOrganization] = useState(OrganizationState);
  const [compressed, setCompressed] = useState();

  useEffect(() => {
    if (id && id != '0') {
      query.id = id;
      getAllOrganization(query);
    }
    getTimeZone();
  }, [id]);

  const getTimeZone = () => {
    fetch('/timezones.json').then(async (res) => {
      const data = await res.json();
      setTimeZoneList(data);
    });
  };

  /**
   * Get organization
   * */
  const getAllOrganization = async (queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;

    setQuery(queryNew);
    setIsLoading(true);

    try {
      const org = await organizationService.organizationGet(queryNew);
      if (org?.data?.length) {
        setOrganization(org.data[0]);
      }
    } catch (error) {
      setError({
        message: error?.message || alertConstants.SERVER_ERROR,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const { name, value, type } = event.target;
    if (type == 'time') {
      setOrganization((prev) => ({
        ...prev,
        [name]: value.toString(),
      }));
      return;
    }
    setOrganization((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleImageUpload = (file, name, compressed, compressedBlob) => {
    setOrganization((prev) => ({
      ...prev,
      [name]: file.pop(),
    }));
    setCompressed(compressedBlob);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true);
    setIsLoading(true);

    if (
      organization?.name.toString().trim() === '' ||
      organization?.company_time_zone === null ||
      organization?.open_time > organization?.close_time
    ) {
      setIsLoading(false);
      return null;
    }
    const isPhoneValid = organization?.phone ? phoneNumberValidation(organization?.phone) : true;

    const isPinValid = organization?.pin_code ? validatePinNumber(organization?.pin_code) : true;

    if (!isPhoneValid || !isPinValid) {
      setIsLoading(false);
      return null;
    }

    try {
      await organizationService.organizationAdd(organization).then(() => {
        setSubmitted(false);
        queryClient.invalidateQueries(['organizationList']);
      });
      navigate(-1);
      // navigate(`/${RT.SETTINGS}/${RT.WORK_SPACE}/${RT.ORGANIZATION}`);
    } catch (error) {
      setError({
        common: error?.message || alertConstants.SERVER_ERROR,
      });
    } finally {
      setIsLoading(false);
      setSubmitted(false);
    }
  };

  let imageUrl = compressed
    ? compressed
    : organization?.logo
    ? apiConstants.imgUrlS3 + organization?.logo
    : null;

  return (
    <div className='org-add-container'>
      {/* component will active when error occurs   */}
      <ErrorComponent error={error?.message} />

      <span className='fz-16px-rem' style={{ fontWeight: 600 }}>
        {id == 0 ? 'Add' : 'Edit'} Organization
      </span>
      <form onSubmit={handleSubmit}>
        <div className='mt-3'>
          <div className='w-fit h-fit-content'>
            {imageUrl ? (
              <img
                src={imageUrl}
                style={{
                  objectFit: 'contain',
                  height: '100%',
                  width: '100%',
                  borderRadius: 200,
                  padding: 2,
                }}
              />
            ) : (
              <CustomFileUpload
                isCompressed={true}
                name='logo'
                accept='image'
                handleOK={handleImageUpload}
                CustomView={<ImageUp color='#d2d2d2' size={80} />}
              />
            )}
          </div>

          {/* {compressed && (
            <img
              src={compressed}
              width={'100px'}
              height={'100px'}
              style={{ objectFit: 'contain' }}
            />
          )} */}
        </div>
        <div className='row mb-1 mt-1'>
          <div className='col-md-6'>
            <div>
              <label htmlFor='name' className='mb-1 fz-13px'>
                Organization Name<span className='field-required'> *</span>
              </label>
              <input
                type='text'
                className='input-box'
                id='name'
                name='name'
                value={organization?.name}
                placeholder='Enter Organization name'
                onChange={(event) => {
                  handleChange(event);
                }}
              />
              {submitted && !organization?.name.trim() && (
                <div className='help-block'>Organization name is required</div>
              )}
            </div>
          </div>
          {/* </div> */}

          {/* <div className='row mb-1'> */}
          <div className='col-md-6'>
            <div>
              <label htmlFor='address' className='mb-1 fz-13px'>
                Address
              </label>
              <input
                type='text'
                className='input-box'
                id='address'
                name='address'
                value={organization?.address}
                placeholder='Enter address '
                onChange={(event) => {
                  handleChange(event);
                }}
              />
            </div>
          </div>
        </div>
        <div className='row mb-1'>
          <div className='col-md-3'>
            <div>
              <label htmlFor='city' className='mb-1 fz-13px'>
                City
              </label>
              <input
                type='text'
                className='input-box'
                id='city'
                name='city'
                value={organization?.city}
                placeholder='Enter city '
                onChange={(event) => {
                  handleChange(event);
                }}
              />
            </div>
          </div>
          <div className='col-md-3'>
            <div>
              <label htmlFor='pin_code' className='mb-1 fz-13px'>
                {' '}
                PIN
              </label>
              <input
                type='number'
                className='input-box'
                id='pin_code'
                name='pin_code'
                // min={0}
                value={organization?.pin_code}
                placeholder='Enter PIN '
                onChange={(event) => {
                  handleChange(event);
                }}
              />
              {submitted &&
                !validatePinNumber(organization?.pin_code) &&
                organization?.pin_code && <div className='help-block'> Invalid PIN code</div>}
            </div>
          </div>
          {/* </div>
        <div className='row mb-1'> */}
          <div className='col-md-6'>
            <div>
              <label htmlFor='email' className='mb-1 fz-13px'>
                Email
              </label>
              <input
                type='email'
                className='input-box'
                id='email'
                name='email'
                value={organization?.email}
                placeholder='Enter email address '
                onChange={(event) => {
                  handleChange(event);
                }}
              />
            </div>
          </div>
        </div>

        <div className='row mb-1'>
          <div className='col-md-6'>
            <div>
              <label htmlFor='phone' className='mb-1 fz-13px'>
                Phone Number
              </label>
              <input
                type='number'
                className='input-box'
                id='phone'
                name='phone'
                value={organization?.phone}
                placeholder='Enter phone number '
                onChange={(event) => {
                  handleChange(event);
                }}
              />
              {submitted && !phoneNumberValidation(organization?.phone) && organization?.phone && (
                <div className='help-block'> Invalid Phone number</div>
              )}
            </div>
          </div>

          <div className='col-md-6'>
            <div>
              <label htmlFor='company-web' className='mb-1 fz-13px'>
                Company Website
              </label>
              <input
                type='text'
                className='input-box'
                id='company-web'
                name='website'
                value={organization?.website}
                placeholder='Enter company website '
                onChange={(event) => {
                  handleChange(event);
                }}
              />
            </div>
          </div>
        </div>

        <div className='row mb-1'>
          {/* Time zone select */}

          <div className='col-md-6 mt-1'>
            <div className='mb-1 fz-13px'>
              Time Zone
              <span className='field-required'> *</span>
            </div>

            <ReactSelect
              name='company_time_zone'
              placeholder='Choose time zone'
              getOptionLabel={(option) => {
                return option?.text;
              }}
              getOptionValue={(option) => {
                return option;
              }}
              options={timeZoneList}
              onChange={(selectedOption) => {
                handleChange({
                  target: {
                    name: 'company_time_zone',
                    value: selectedOption,
                  },
                });
              }}
              value={organization?.company_time_zone}
              styles={{
                ...CommonDropDownStyle,
                indicatorsContainer: (styles) => {
                  return {
                    ...styles,
                    minHeight: '25px',
                    // height: '33px',
                    color: '#87909E',
                  };
                },
                control: (styles, { isFocused, selectProps }) => {
                  const hasError = selectProps?.className?.includes('field-error');
                  return {
                    ...styles,
                    padding: '0',
                    height: '34px',
                    minHeight: '25px',
                    borderColor: hasError ? '#EA4D4D' : isFocused ? '#3454D1' : '#87909E',
                    boxShadow:
                      isFocused && !hasError
                        ? '0 0 0 3px rgba(52, 84, 209, 0.25)'
                        : hasError && isFocused
                        ? ' 0 0 0 3px rgba(255, 0, 0, 0.15)'
                        : styles.boxShadow,
                    '&:hover': {
                      borderColor:
                        isFocused && !hasError
                          ? '#3454D1'
                          : hasError
                          ? '#EA4D4D'
                          : styles['&:hover']?.borderColor || styles.borderColor,
                    },
                  };
                },
              }}
            />

            {submitted && organization?.company_time_zone == null && (
              <div className='help-block'> Time Zone selection is required</div>
            )}
          </div>

          {/* end of Time zone select */}

          <div className='col-md-3'>
            <div>
              <label htmlFor='open_time' className='mb-1 fz-13px'>
                Opening Time
              </label>
              <input
                type='time'
                className='input-box'
                id='open_time'
                name='open_time'
                onChange={(event) => {
                  handleChange(event);
                }}
                value={organization?.open_time}
              />
            </div>
          </div>
          <div className='col-md-3'>
            <div>
              <label htmlFor='close_time' className='mb-1 fz-13px'>
                Closing Time
              </label>
              <input
                type='time'
                className='input-box '
                id='close_time'
                name='close_time'
                value={organization?.close_time}
                onChange={(event) => {
                  handleChange(event);
                }}
              />

              {submitted && organization?.open_time > organization?.close_time && (
                <div className='help-block'>Opening time must be later than closing time.</div>
              )}
            </div>
          </div>
        </div>

        <div className='org-add-button-container'>
          {id == 0 && (
            <Button.Container
              className={'outline-cancel-button'}
              type='reset'
              handleOk={() => {
                setSubmitted(false);
                setIsLoading(false);
                setOrganization(OrganizationState);
              }}
            >
              <Button.Title title={'Cancel'} />
            </Button.Container>
          )}
          <Button.Container type='submit' className={'blue-btn'} isDisabled={isLoading}>
            <Button.Title title={'Save'} />
            <Button.Loading isLoading={isLoading} />
          </Button.Container>
        </div>
      </form>
    </div>
  );
}
