/* eslint-disable no-unused-vars */
import { keepPreviousData, useInfiniteQuery, useQuery } from '@tanstack/react-query';
import parse from 'html-react-parser';
import { CalendarCheck, FileClock } from 'lucide-react';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ReactCalendarScheduler } from 'react-calendar-scheduler';
import { useSearchParams } from 'react-router-dom';
import { Modal } from '../_components';
import CircularAvatar from '../_components/Avatar/CircularAvatar';
import StackedAvatars from '../_components/Avatar/StackedAvatars';
import ToolTip from '../_components/Tooltip/ToolTip';
import { apiConstants, COLOR_CONSTANT, ROLE, VISIBLE_EVENTS } from '../_constants';
import useDebounce from '../_helpers/useDebounce';
import { taskService, userService } from '../_services';
import { calendarEventService } from '../_services/calendar.service';
import useObserver from '../Hook/useObserver';
import { useUser } from '../Hook/useUser';
import { reminderSelect } from '../Task/taskConstants';
import {
  convertToEditorState,
  getReminderActive,
  getSubTaskIsActive,
  LinkOptions,
  removeTagsAndGetContent,
} from '../Task/taskValidation';
import { IconWithLabel } from '../Template/SubComponents/ClickableSelect';
import { convertHtmlToRawContent, filterNullValues, fullNameUser, GlobalContext } from '../Utils';
import {
  CheckMark,
  LoadingIcon,
  ReminderTabIcon,
  SubtaskIcon,
  TodoTabIcon,
} from '../Utils/SvgIcons';
import CalendarEventAdd from './CalendarEventAdd';
import { CalendarReminderAdd } from './CalendarReminderAdd';
import TaskSingleViewModal from './TaskSingleViewModal';
import './time_box_calendar.css';
import TimeBoxFilters from './TimeBoxFilters';
import {
  CALENDAR_FROM,
  getArrayParam,
  getColorOfEvent,
  getDateTimeLocalBySting,
  getDateWithTime,
  getEventClass,
  getTypeOfEvent,
  processTasks,
} from './TimeBoxHelperFunctions';

const TimeBoxCalendar = ({
  defaultPayload = null,
  userData = useUser(),
  isFrom = CALENDAR_FROM.CALENDAR,
  isNeedProject = true,
  isNeedTemplate = true,
  isNeedClient = true,
  ...additionalProps
}) => {
  const containerRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const { makeAlert } = useContext(GlobalContext);
  const isMenuSubTaskOn = getSubTaskIsActive();
  const isMenuReminderOn = getReminderActive();

  const users = getArrayParam(searchParams, 'user_id'); // to get all user_id as array
  const project_id = getArrayParam(searchParams, 'project_id'); // to get the project filters from the task list calendar view
  const template_id = getArrayParam(searchParams, 'template_id'); // to get the template filters from the task list calendar view"
  const client_id = getArrayParam(searchParams, 'client_id'); // to get the template filters from the task list calendar view
  const label = getArrayParam(searchParams, 'label_id');
  const priority = getArrayParam(searchParams, 'priority_id');

  const filteredPayload = {
    ...filterNullValues(defaultPayload),
    userIds: users,
  };

  const [portalElement, setPortalElement] = useState(null);
  const [eventAddModal, setEventAddModal] = useState({ modal: false, data: {} });
  const [eventEditModal, setEventEditModal] = useState({
    modal: false,
    data: {},
    isEditable: false,
  });
  const [taskViewModal, setTaskViewModal] = useState({ modal: false, data: {} });
  const taskFilterPayload = filteredPayload ? { taskFilters: filteredPayload } : {};
  const [calendarType, setCalendarType] = useState(
    additionalProps.viewType
      ? additionalProps.viewType
      : isFrom == CALENDAR_FROM.CALENDAR
      ? 'week'
      : 'team',
  );
  const [selectedUsers, setSelectedUsers] = useState(new Array(userData));

  // filter selection
  const [selection, setSelection] = useState({
    fromDate: moment().startOf('month').startOf('day').valueOf(),
    endDate: moment().endOf('month').endOf('day').valueOf(),
    ...taskFilterPayload,
    visibleEvents: [
      VISIBLE_EVENTS.TASK,
      ...(isMenuSubTaskOn ? [VISIBLE_EVENTS.SUBTASK] : []), // Include subtask if allowed
      ...(isMenuReminderOn ? [VISIBLE_EVENTS.REMINDER] : []), // Include reminder if allowed
    ],
  });

  const searchKey = searchParams.get('search') ? searchParams.get('search') : '';

  const debouncedSearch = useDebounce(searchKey, 500);

  console.log({ calendarEventPayload: template_id, isNeedTemplate });

  // for main calendar list events
  const {
    data: eventList,
    isLoading,
    refetch: eventRefetch,
  } = useQuery({
    queryKey: [
      'event-list',
      selection,
      users,
      project_id,
      template_id,
      client_id,
      debouncedSearch,
      priority,
      label,
      selection.fromDate,
    ],
    queryFn: () =>
      calendarEventService.getEventList({
        ...selection,
        taskFilters: {
          ...selection.taskFilters,
          userIds: additionalProps?.userType == 'personal' ? [userData.id] : users ?? [],
          project_id: additionalProps?.project_id
            ? additionalProps?.project_id
            : isNeedProject
            ? project_id
            : defaultPayload?.project_id,
          template_id: isNeedTemplate ? template_id : defaultPayload?.template_id,
          client_id: isNeedClient || !isNeedProject ? client_id : defaultPayload?.client_id,
          priority: priority,
          label: label,
        },
        searchKey: debouncedSearch,
      }),
    select: (res) => res.data,
    enabled: calendarType != 'team' ? true : false,
  });

  // for team calendar events
  const {
    data: userWithTask,
    error: reportError,
    isSuccess,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading: userWithTaskLoading,
  } = useInfiniteQuery({
    queryKey: [
      'userList',
      selection,
      calendarType,
      users,
      project_id,
      template_id,
      client_id,
      priority,
      label,
      debouncedSearch,
    ],
    queryFn: ({ pageParam = 1 }) =>
      userService.userListWithTaskList({
        fromDate: moment(selection.fromDate).startOf('week').valueOf(), // get start of week by fromDate
        endDate: moment(selection.fromDate).endOf('week').valueOf(), // get end of week by fromDate
        isTaskNeed: selection?.visibleEvents?.includes(VISIBLE_EVENTS.TASK), // for param if task is needed
        isSubTaskNeed: selection?.visibleEvents?.includes(VISIBLE_EVENTS.SUBTASK), // for param if subtask is needed
        user_id: additionalProps?.userType == 'personal' ? [userData.id] : users ?? [], // users by params
        project_id: additionalProps?.project_id
          ? additionalProps?.project_id
          : isNeedProject
          ? project_id
          : defaultPayload?.project_id,
        client_id: isNeedClient || !isNeedProject ? client_id : defaultPayload?.client_id,
        template_id: isNeedTemplate ? template_id : defaultPayload?.template_id,
        pageVo: { noOfItems: 10, pageNo: pageParam }, // pagination instruction set
        priority: priority,
        label: label,
        searchKey: debouncedSearch,
      }),
    enabled: calendarType == 'team' ? true : false,
    getNextPageParam: (response) =>
      response.data.page < response.data.pages ? response.data.page + 1 : undefined,
    refetchOnWindowFocus: false,
  });

  // observer for call next page for team option if hasNextPage available
  const { observerRef } = useObserver({
    onIntersection: () => {
      hasNextPage && fetchNextPage();
    },
    dependency: [hasNextPage],
  });

  // Extract the data from each page
  const flattedUpdateData = React.useMemo(() => {
    const allData = userWithTask?.pages?.flatMap((page) => page.data.data);
    return { allData };
  }, [userWithTask]);

  const teamTasks = processTasks(flattedUpdateData?.allData); // to convert team tasks to flat map array

  const myTeam = flattedUpdateData?.allData?.map((item) => {
    const { userData } = item;
    const userName = fullNameUser(userData.first_name, userData.last_name); // Get the user name
    const isMale = userData?.gender === 1;
    const isNeutral = userData?.gender === 3;
    const url = userData?.compressed_image_url
      ? userData?.compressed_image_url
      : userData?.image_url;
    const finalUrl = url ? apiConstants.imgUrlS3 + url : null;
    // to display the team option = team list
    return {
      userId: userData?.user_id,
      profileComponent: (
        <div className='user-info'>
          <div className='d-flex align-items-center'>
            <CircularAvatar
              size={25}
              className='fz-none zoom-in employee-img-with-low-border'
              source={finalUrl}
              isMale={isMale}
              isNeutral={isNeutral}
            />
            <span style={{ fontWeight: 600, fontSize: 14 }}>{userName}</span>
          </div>
        </div>
      ),
    };
  });

  useEffect(() => {
    const element = document.querySelector('.ib__sc__header__center');
    if (element) {
      setPortalElement(element);
    }
  }, [containerRef.current]);

  const isReminderSelected = selection.visibleEvents.includes(VISIBLE_EVENTS.REMINDER); // selection element for reminder
  const isLogSelected = selection.visibleEvents.includes(VISIBLE_EVENTS.LOG); // selection element for log
  const isEventSelected = selection.visibleEvents.includes(VISIBLE_EVENTS.EVENT); // selection element for event
  const isSubtaskSelected = selection.visibleEvents.includes(VISIBLE_EVENTS.SUBTASK); // selection element for subtask
  const isTodoSelected = selection.visibleEvents.includes(VISIBLE_EVENTS.TASK); // selection element for task

  // handler for visible events  - task,subtask,reminder,log
  const toggleVisibleEvents = (event) => {
    if (selection.visibleEvents.includes(event)) {
      setSelection((prev) => ({
        ...prev,
        visibleEvents: prev.visibleEvents.filter((item) => item !== event),
      }));
      return;
    }
    setSelection((prev) => ({
      ...prev,
      visibleEvents: [...prev.visibleEvents, event],
    }));
  };

  // for event component header section
  const EventTitle = ({ title, type, event }) => {
    let color = '';
    let icon = '';
    let barColor = '';
    if (VISIBLE_EVENTS.REMINDER === type) {
      color = COLOR_CONSTANT.red.text;
      icon = <ReminderTabIcon color={color} />;
      barColor = COLOR_CONSTANT.red.bar;
    } else if (VISIBLE_EVENTS.SUBTASK === type) {
      color = COLOR_CONSTANT.blue.text;
      icon = <SubtaskIcon color={color} />;
      barColor = COLOR_CONSTANT.blue.bar;
    } else if (VISIBLE_EVENTS.TASK === type) {
      color = COLOR_CONSTANT.green.text;
      icon = <TodoTabIcon color={color} />;
      barColor = COLOR_CONSTANT.green.bar;
    } else if (VISIBLE_EVENTS.LOG === type) {
      color = COLOR_CONSTANT.violet.text;
      icon = <TodoTabIcon color={color} />;
      barColor = COLOR_CONSTANT.violet.bar;
    } else if (VISIBLE_EVENTS.EVENT == type) {
      color = COLOR_CONSTANT.orange.text;
      icon = <TodoTabIcon color={color} />;
      barColor = COLOR_CONSTANT.orange.bar;
    }

    const startTime = moment(parseInt(event.start_date)).format('hh:mm a'); // to display the start time
    const endTime = moment(parseInt(event.end_date)).format('hh:mm a'); // to display the end time

    return (
      <>
        <div className='calendar__event__title_wrap'>
          <ToolTip tooltipText={title} isModern className='pointer' popOver>
            <div
              className='calendar__event__title'
              style={{
                color: color,
                borderLeft: `3px solid ${barColor}`,
                paddingLeft: 5,
                width: calendarType == 'team' ? 150 : 175,
              }}
            >
              {title}
              <span className='d-flex fs-10'>
                {startTime} - {endTime}
                {event?.status_info?.name == 'done' && (
                  <div className='ms-2 calendar__event__body_wrap'>
                    <div className='calendar__event__title' style={{ color: color }}>
                      <CheckMark height={13} width={13} />
                    </div>
                  </div>
                )}
              </span>
            </div>
          </ToolTip>
          {event?.note && removeTagsAndGetContent(event?.note) && (
            <ToolTip
              tooltipText={parse(event?.note, LinkOptions)}
              isModern
              className='pointer'
              popOver
            >
              <div className='calendar__event__body_wrap'>
                <div className=' pt-2 align-items-center'>
                  {Boolean(event?.note) && (
                    <div className='task-list-description calendar-event-note'>
                      {event?.note?.length > 140
                        ? convertToEditorState(event?.note).substring(0, 140) + '...'
                        : convertToEditorState(event?.note)}
                    </div>
                  )}
                  {/* {parse(event?.note, LinkOptions)} */}
                </div>
              </div>
            </ToolTip>
          )}
          <div className='calendar__event__body_wrap pt-3'>
            <div className='calendar__event__title calendar-avatar' style={{ color: color }}>
              <StackedAvatars
                getSourceByKey={(user) =>
                  user.compressed_img_url ? user.compressed_img_url : user.img_url
                }
                getNameByKey={(user) => user.name}
                getGenderByKey={(user) => user.gender === 1}
                imageList={event.user_info}
                showInitials={false}
                size={18}
                tooltipPopOver
                limit={3}
                tooltipPosition='left'
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  // calendar header component
  const CalendarHeader = () => (
    <div className='d-flex gap-2'>
      <div
        onClick={() => toggleVisibleEvents(VISIBLE_EVENTS.TASK)}
        className={`calendar-subtask-btn ${!isTodoSelected ? 'light-temp-border' : ' active'}`}
      >
        <IconWithLabel
          hasValue={true}
          label={'Task'}
          icon={<TodoTabIcon color={isTodoSelected ? COLOR_CONSTANT.green.text : '#d2d2d2'} />}
        />
      </div>
      {isMenuSubTaskOn && (
        <div
          onClick={() => toggleVisibleEvents(VISIBLE_EVENTS.SUBTASK)}
          className={` calendar-task-btn  ${!isSubtaskSelected ? 'light-temp-border' : ' active'}`}
        >
          <IconWithLabel
            hasValue={true}
            label={'Subtask'}
            icon={<SubtaskIcon color={isSubtaskSelected ? COLOR_CONSTANT.blue.text : '#d2d2d2'} />}
          />
        </div>
      )}
      {calendarType != 'team' && (
        <>
          {isMenuReminderOn && (
            <div
              className={`calendar-reminder-btn ${
                !isReminderSelected ? 'light-temp-border' : ' active'
              }`}
              onClick={() => toggleVisibleEvents(VISIBLE_EVENTS.REMINDER)}
            >
              <IconWithLabel
                hasValue={true}
                label={'Reminder'}
                icon={
                  <ReminderTabIcon
                    color={isReminderSelected ? COLOR_CONSTANT.red.text : '#d2d2d2'}
                  />
                }
              />
            </div>
          )}
          <div
            className={` calendar-event-btn ${!isEventSelected ? 'light-temp-border' : ' active'}`}
            onClick={() => toggleVisibleEvents(VISIBLE_EVENTS.EVENT)}
          >
            <IconWithLabel
              hasValue={true}
              label={'Events'}
              icon={
                <CalendarCheck
                  size={15}
                  color={isEventSelected ? COLOR_CONSTANT.orange.text : '#d2d2d2'}
                />
              }
              // labelColor={isReminderSelected ? COLOR_CONSTANT.red.text : '#2a2e34'}
            />
          </div>
          <div
            className={` calendar-log-btn ${!isLogSelected ? 'light-temp-border' : ' active'}`}
            onClick={() => toggleVisibleEvents(VISIBLE_EVENTS.LOG)}
          >
            <IconWithLabel
              hasValue={true}
              label={'Log'}
              icon={
                <FileClock
                  size={15}
                  color={isLogSelected ? COLOR_CONSTANT.violet.text : '#d2d2d2'}
                />
              }
              // labelColor={isReminderSelected ? COLOR_CONSTANT.red.text : '#2a2e34'}
            />
          </div>
        </>
      )}
    </div>
  );

  /**
   * getEventDetails - returns the event details - todo,subtask,reminder
   * @param {Object} event - event details
   * @returns returns the event details object for calendar
   */
  const getEventDetails = (event) => {
    const { date: startDate, time: startTime } = getDateWithTime(event?.start_date);
    const { date: endDate, time: endTime } = getDateWithTime(event?.end_date);

    return {
      title: <EventTitle title={event?.name} type={getTypeOfEvent(event)} event={event} />,
      startDate: startDate,
      endDate: endDate,
      startTime: startTime,
      endTime: endTime,
      id: event?.id,
      bg_color: getColorOfEvent(event),
      editable: false,
      userId: event?.user_id ?? event?.user_info?.id,
      draggable: false,
      resizable: false,
      eventDetails: event,
      custom_class: getEventClass(event),
    };
  };

  /**
   * handleEventClick - event modal show with reminder or redirect to task
   * @param {Object} event
   */
  const handleEventClick = async (event) => {
    if (event?.eventDetails?.event) {
      const eventData = {
        title: event?.eventDetails?.name,
        description: event?.eventDetails?.note,
        allocated_users: event?.eventDetails?.user_info?.length
          ? event?.eventDetails?.user_info?.map((item) => {
              return {
                ...item,
                image_url: item.img_url,
              };
            })
          : [],
        type: 2,
        label: event?.eventDetails?.labels_info?.length
          ? event?.eventDetails?.labels_info?.map((item) => {
              return item?.id?.toString();
            })
          : [],
        reminder_date: new Date(parseInt(event?.eventDetails?.start_date)),
        start_time: moment(parseInt(event?.eventDetails?.start_date)).format('HH:mm'),
        end_time: moment(parseInt(event?.eventDetails?.end_date)).format('HH:mm'),
        task_id: event?.eventDetails?.task_id,
        id: event?.eventDetails?.id,
        note: convertHtmlToRawContent(event?.eventDetails?.note),
      };
      setEventEditModal({
        modal: true,
        data: {
          ...eventData,
          event: true,
        },
        isEditable: event?.eventDetails?.user_id == userData.id || userData?.role == ROLE.ADMIN, // only edit can be possible to admin
      });
      return;
    }
    if (event?.eventDetails?.reminder) {
      const reminderData = await taskService.getReminderById({
        id: event?.eventDetails?.id,
        select: reminderSelect,
      });
      setEventAddModal({
        modal: true,
        data: {
          ...reminderData.data,
          reminder_date: new Date(parseInt(reminderData?.data?.reminder_date)),
          reminder: true,
          user_info: event?.eventDetails?.user_info, // only setting if it's view or edit state
        },
      });
      return;
    } else if (event?.eventDetails?.sub_task) {
      setTaskViewModal({ modal: true, data: { id: event?.eventDetails?.task_id, tab: 'subtask' } });
      // navigate(`/${RT.TASK}/${event?.eventDetails?.task_id}?tab=${'subtask'}`);
    } else {
      setTaskViewModal({
        modal: true,
        data: { id: event?.eventDetails?.task_id, tab: 'work-history' },
      });
      // navigate(`/${RT.TASK}/${event?.eventDetails?.id}?tab=${'work-history'}`);
    }
  };

  const userId =
    filteredPayload?.userIds && filteredPayload?.userIds?.length === 1
      ? filteredPayload?.userIds?.[0]
      : null;

  // specific user for user selection - return user object
  const { data: specificUser } = useQuery({
    queryKey: ['task-add-for-user', userId],
    queryFn: () =>
      userService.userGet({
        select: ['id', 'first_name', 'last_name', 'gender', 'image_url'],
        id: userId,
      }),
    select: (data) => {
      return data?.data;
    },
    enabled: Boolean(userId),
    placeholderData: keepPreviousData,
  });

  /**
   * handleColumnClick - event modal for add reminder
   * @param {Object} event - event object with start date with time and end date with time
   * @returns returns to show add reminder popup modal
   */
  const handleColumnClick = (event) => {
    // to get the date-time local type date
    const reminder_date = getDateTimeLocalBySting(event?.startDate, event?.startTime);
    const endDate = getDateTimeLocalBySting(event?.endDate, event?.endTime);

    setEventAddModal({
      modal: true,
      data: {
        reminder_date,
        endDate,
        user_id:
          filteredPayload?.userIds?.length == 1 ? filteredPayload?.userIds?.[0] : userData.id,
      },
    });
  };
  /**
   * closeEditing - close event add modal
   */
  const closeEditing = () => {
    setEventAddModal({ modal: false, data: {} });
  };

  const handleDateChange = (date) => {
    if (calendarType != 'team') {
      setSelection((prev) => ({
        ...prev,
        fromDate: moment(date).startOf('month').valueOf(),
        endDate: moment(date).endOf('month').valueOf(),
      }));
    } else {
      setSelection((prev) => ({
        ...prev,
        fromDate: moment(date).startOf('week').valueOf(),
        endDate: moment(date).endOf('week').valueOf(),
      }));
    }
  };

  return (
    <div className='calendar-content ' style={{ width: 'inherit' }}>
      <div style={{ fontSize: '12px', flex: 1 }}>
        {CALENDAR_FROM.CALENDAR == isFrom && (
          <TimeBoxFilters
            setSelectedUsers={setSelectedUsers} /**to set the state of selected members */
            selectedUsers={selectedUsers} /** selected members state */
            filteredPayload={selection}
            setFilteredPayload={setSelection}
            currentUser={userData}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            isNeedProject={isNeedProject}
            isNeedTemplate={isNeedTemplate}
            isNeedUser={additionalProps?.userType == 'personal'}
            isFrom={isFrom}
          />
        )}
        {(isLoading || userWithTaskLoading) && (
          <div className='loading-wrapper'>
            <LoadingIcon color={'#c1c1c1'} size={70} />
          </div>
        )}
        <div className='calendar-wrap' ref={containerRef}>
          <ReactCalendarScheduler
            onNextClick={(date) => {
              console.log({ handleNextClick: date });
              handleDateChange(date);
            }}
            onPrevClick={(date) => {
              console.log({ handlePrevClick: date });
              handleDateChange(date);
            }}
            calendarHeader={<CalendarHeader />}
            events={
              calendarType != 'team'
                ? eventList?.length > 0
                  ? eventList.map((event) => {
                      return getEventDetails(event);
                    })
                  : []
                : teamTasks?.length > 0
                ? teamTasks.map((event) => {
                    return getEventDetails(event);
                  })
                : []
              // events
            }
            teams={myTeam}
            onCalendarTypeChange={(type) => {
              setCalendarType(type);
            }}
            showAddNewEventButton={false}
            selectedDate={
              calendarType == 'team' || calendarType == 'month'
                ? new Date(selection?.fromDate)
                : new Date()
            }
            calendarType={
              additionalProps.viewType
                ? additionalProps.viewType
                : isFrom == CALENDAR_FROM.CALENDAR
                ? 'week'
                : 'team'
            } // week or day
            monthViewMinCellHeight={150} //day column title formate
            weekHourCellHeight={150} //Height of the hour box
            startingWeekday={0} // 0 for sunday, 1 for monday, 2 for tuesday, 3 for wednesday, 4 for thursday, 5 for friday, 6 for saturday
            weekViewNextButtonDayIncrement={1}
            eventHeight={120} //minimum event thickness
            eventWidth={100}
            minimumEventHeight={150}
            disableEventModal={true}
            onEventClick={(event) => {
              handleEventClick(event);
            }}
            disableAddEventModal
            isShowAddNewEventButton={false}
            onColumnClick={(event) => {
              handleColumnClick(event);
            }}
            minimumEventWidth={200}
          />
          <div
            ref={observerRef}
            className='d-flex justify-content-center'
            style={{ minHeight: '2px' }}
          >
            {hasNextPage && isFetchingNextPage && isLoading && calendarType == 'team' && (
              <div
                className='cursor-pointer'
                onClick={() => hasNextPage && fetchNextPage()}
                style={{ height: '10px' }}
              >
                <LoadingIcon size={60} />
              </div>
            )}
          </div>
        </div>
        {eventAddModal.modal && (
          <CalendarEventAddOrUpdateModal
            closeEditing={closeEditing}
            data={eventAddModal.data}
            users={filteredPayload?.userIds}
            specificUser={specificUser}
            userData={userData}
            eventRefetch={eventRefetch}
          />
        )}
        {eventEditModal.modal && (
          <Modal.Container className={'model_contain reminder-modal'}>
            <Modal.Head
              containerClass={'task-add-head'}
              Heading={'Event Edit'}
              handleClose={() => setEventEditModal({ modal: false, data: {} })}
            ></Modal.Head>
            <Modal.View className={'reminder-add-modal-container'}>
              <div className={`d-flex align-items-center px-4 justify-content-between pt-4 `}>
                <div className='d-flex align-items-center gap-3'>
                  <span className='font-16'>Event Edit</span>
                </div>
              </div>
              <CalendarEventAdd // for event edit
                closeEditing={() => setEventEditModal({ modal: false, data: {} })}
                data={eventEditModal.data}
                handleAdd={() => {
                  eventRefetch();
                  setEventEditModal({ modal: false, data: {} });
                }}
                isEditable={eventEditModal.isEditable}
              />
            </Modal.View>
          </Modal.Container>
        )}
        {taskViewModal.modal && (
          <TaskSingleViewModal
            handleClose={setTaskViewModal}
            taskId={taskViewModal?.data?.id}
            tab={taskViewModal?.data?.tab}
          />
        )}
      </div>
    </div>
  );
};

export default TimeBoxCalendar;

const CalendarEventAddOrUpdateModal = ({
  closeEditing,
  data,
  users,
  specificUser,
  userData,
  eventRefetch,
}) => {
  let defaultAssignee = users?.length == 1 && specificUser ? specificUser : userData;
  let defaultUserId = users?.length == 1 && specificUser ? specificUser?.id : userData?.id;

  // if it has id then there must be a user_info.
  // So setting defaultAssignee and defaultUserId as corresponding data
  if (data?.id && data?.user_info?.length > 0) {
    defaultAssignee = data?.user_info?.[0];
    defaultUserId = data?.user_info?.[0]?.id;
  }

  return (
    <CalendarReminderAdd
      closeEditing={closeEditing}
      data={data}
      defaultAssignee={defaultAssignee}
      userId={defaultUserId}
      eventRefetch={eventRefetch}
    />
  );
};

// commented

// if (event.sub_task) {
//   const { date: startDate, time: startTime } = getDateWithTimeSubTask(event?.start_date);
//   const { date: endDate, time: endTime } = getDateWithTimeSubTask(event?.end_date);
//   return {
//     title: <EventTitle title={event.name} type={getTypeOfEvent(event)} />,
//     startDate: startDate,
//     endDate: endDate,
//     startTime: startTime,
//     endTime: endTime,
//     bg_color: getColorOfEvent(event),
//     draggable: false,
//     eventDetails: event,
//   };
// }
