export const DELETE_NOTE = {
  DEPARTMENT:
    'Are you certain you wish to delete this department? Deleting it will result in the automatic removal of all related designations. Please note that this action cannot be reversed.',
  DESIGNATION: 'Are you certain you wish to delete this designation? This action is irreversible.',
  ORGANIZATION:
    'Are you certain you wish to delete this organization? Deleting it will result in the automatic removal of all related departments, designations, and company-based users. Active users will be logged out. Please note that this action cannot be reversed.',
  PROJECT: 'Are you certain you wish to delete this project? This action is irreversible.',
  OKR: 'Are you certain you wish to delete this okr? This action is irreversible.',
  MYTEAM: 'Are you certain you wish to delete this user? This action is irreversible.',
  BRANCH: 'Are you certain you wish to delete this branch? This action is irreversible.',
  LABEL: 'Are you certain you wish to delete this label? This action is irreversible.',
  TEAM: 'Are you certain you wish to delete this Team? This action is irreversible.',
  CLIENT: 'Are you certain you wish to delete this Client? This action is irreversible.',
  TEMPLATE: 'Are you certain you wish to delete this template? This action is irreversible.',
  RECURRENCE: 'Are you certain you wish to delete this recurrence? This action is irreversible.',
  FOLDER: 'Are you certain you wish to delete this folder? This action is irreversible.',
  FILE: 'Are you certain you wish to delete this file? This action is irreversible.',
  SUBTASK: 'Are you certain you wish to delete this Subtask? This action is irreversible.',
  RELATED_TASK:
    'Are you certain you wish to delete this related task? This action is irreversible.',
  MILESTONE: 'Are you certain you wish to delete this Milestone? This action is irreversible.',
  SPRINT: 'Are you certain you wish to delete this Sprint? This action is irreversible.',
  TASK: [
    'Deleting this task will also remove all related ',
    'subtasks',
    ', disable ',
    'reminders',
    ', end ',
    'recurrence',
    ', and clear task ',
    'chat history',
    '. Do you wish to proceed?',
  ],
  TASK_COMPLETE: 'Are you sure you want to mark this task as complete?',
};
export const MODAL_NOTES = {
  TEMPLATE_DUPLICATE: 'Are you sure you want to proceed?',
};
