import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query';
import { ArrowLeftIcon, Home, Plus } from 'lucide-react';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ReactSelect from 'react-select';
import { ClientAdd } from '../Client';
import useOutsideClickHandler from '../Hook/useOutsideClickHandler';
import { PROJECT_IDS } from '../Settings/SettingsLabels';
import { getStartDateMilliSecond } from '../Task/taskValidation';
import ErrorBlock from '../Template/SubComponents/ErrorBlock';
import { fullNameUser, GlobalContext, ProjectState } from '../Utils';
import { Images } from '../Utils/Images';
import { ProjectIcon } from '../Utils/SvgIcons';
import { CustomFileUpload, Modal, ToggleButton } from '../_components';
import CircularAvatar from '../_components/Avatar/CircularAvatar';
import { Button } from '../_components/CustomButton';
import { ErrorComponent } from '../_components/ErrorComponent';
import IconLabelSet from '../_components/IconLabelSet';
import { CustomDropdownIndicator } from '../_components/SelectionDropDownIcon';
import ToolTip from '../_components/Tooltip/ToolTip';
import UserDropDown from '../_components/UserDropDown';
import { apiConstants, MENU, RT } from '../_constants';
import { projectConstants } from '../_constants/project.constant';
import useDebounce from '../_helpers/useDebounce';
import { clientService, userService } from '../_services';
import { projectService } from '../_services/project.service';
import { templateService } from '../_services/template.service';
import { projectSchema } from './ProjectValidation';
import { UserListSelectionPopup } from './UserListSelectionPopup';
import {
  DropDownStyle,
  PROJECT_REQUIRED_FIELD_MSG,
  projectAddProjectSelect,
} from './project.constants';

export function ProjectAdd() {
  const { makeAlert, permissionsMap, getMenuLabelName } = useContext(GlobalContext);
  const [searchParams] = useSearchParams();
  const LABEL_OF_CLIENT = getMenuLabelName(MENU.contact.default_name);
  const LABEL_OF_PROJECT = getMenuLabelName(MENU.project.default_name);

  const PROJECT_PERMISSION_MESSAGE = {
    NO_PERMISSIONS_FOR_ADD_PROJECT: `You have no permission to Add ${LABEL_OF_PROJECT}`,
    NO_PERMISSIONS_FOR_EDIT_PROJECT: `You have no permission to Edit ${LABEL_OF_PROJECT}`,
    // NO_PERMISSIONS_FOR_VIEW_LIST: `You have no permission to View ${LABEL_OF_PROJECT}`,
  };
  // useState section
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [project, setProject] = useState(ProjectState);
  const [userSearch, setUserSearch] = useState(''); // state for  user list search
  const [projectOwnerSearch, setProjectOwnerSearch] = useState(''); // state for  user list search
  const [userListPopup, setUserListPopup] = useState(false); // state for  user list search
  const [compressed, setCompressed] = useState(null);

  const [clientSearch, setClientSearch] = useState(''); // state for  user list search
  const [query, setQuery] = useState({
    select: projectAddProjectSelect,
  });

  const [editProjectData, setEditProjectData] = useState({});

  let today = moment().format('YYYY-MM-DD');

  // hooks section
  const { id } = useParams(); // Access the id parameter from the URL
  const navigate = useNavigate();
  const [templateSearch, setTemplateSearch] = useState('');
  const debouncedTemplate = useDebounce(templateSearch, 500);

  const debounceClient = useDebounce(clientSearch, 500);
  const debounceUser = useDebounce(userSearch, 500);
  const debounceUser2 = useDebounce(projectOwnerSearch, 500);

  const [addContactModal, setContactAddModal] = useState(false);

  useMemo(() => {
    if (
      permissionsMap?.project_add != undefined &&
      !permissionsMap?.project_add &&
      id &&
      id == '0'
    ) {
      makeAlert(PROJECT_PERMISSION_MESSAGE.NO_PERMISSIONS_FOR_ADD_PROJECT);
      navigate('/');
    }
    if (
      permissionsMap?.project_add != undefined &&
      !permissionsMap?.project_add &&
      id &&
      id != '0'
    ) {
      makeAlert(PROJECT_PERMISSION_MESSAGE.NO_PERMISSIONS_FOR_EDIT_PROJECT);
      navigate('/');
    }
  }, [permissionsMap?.project_add]);
  useEffect(() => {
    if (id && id != '0') {
      query.id = id;
      getAllProject(query);
    }
  }, [id]);

  const { globalSettings } = useContext(GlobalContext);

  const projectSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings.name === 'project-settings')?.labels;

  const hasExist = (labelID) => {
    const setting = projectSettings?.find((label) => label.id == labelID);
    return setting ? setting.value : undefined;
  };

  const { containerRef: userListRef } = useOutsideClickHandler({
    onClose: () => {
      setUserSearch('');
      setUserListPopup(false);
    },
  });

  // get Project

  const getAllProject = async (queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;

    setQuery(queryNew);
    setIsLoading(true);

    try {
      const project = await projectService.projectGet(queryNew);
      if (project?.data) {
        let s_date = project?.data?.start_date
          ? moment(parseInt(project.data?.start_date)).format('YYYY-MM-DD')
          : null;
        let e_date = project?.data?.end_date
          ? moment(parseInt(project.data?.end_date)).format('YYYY-MM-DD')
          : null;
        let templates = project.data.template_details;
        let client = project.data.client_details?.id ? project.data.client_details : 0;
        let allocated_users = project.data.project_allocated_user;
        let owner = project.data.project_owner_details;
        let team_lead = project.data.project_team_lead_details;
        // let compressed_logo = [project.data.compressed_logo];

        const result = project.data;

        setProject({
          ...result,
          start_date: s_date,
          end_date: e_date,
          template_id: templates,
          client_id: client,
          allocated_users: allocated_users,
          project_owner_id: owner,
          team_lead: team_lead,
          // compressed_logo: compressed_logo,
        });
        setEditProjectData({
          ...result,
          start_date: s_date,
          end_date: e_date,
          template_id: templates,
          client_id: client?.id ? client : 0,
          allocated_users: allocated_users,
          project_owner_id: owner,
          team_lead: team_lead,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // get all user list
  const {
    data: userData,
    error: userError,
    isLoading: isUserLoading,
  } = useQuery({
    queryKey: ['userList', debounceUser],
    queryFn: () =>
      userService.userList({
        searchKey: userSearch,
        select: ['id', 'first_name', 'last_name', 'image_url', 'gender', 'compressed_image_url'],
      }),
    placeholderData: keepPreviousData,
  });

  // get all project owner list
  const {
    data: projectOwnerUserData,
    error: projectOwnerError,
    isLoading: isProjectOwnerLoading,
  } = useQuery({
    queryKey: ['project-owner-list', debounceUser2],
    queryFn: () =>
      userService.userList({
        searchKey: projectOwnerSearch,
        select: ['id', 'first_name', 'last_name', 'image_url', 'gender', 'compressed_image_url'],
      }),
    placeholderData: keepPreviousData,
  });

  // get all client
  const {
    data: clientData,
    error: clientError,
    isLoading: isClientLoading,
    refetch: clientRefetch,
  } = useQuery({
    queryKey: ['clientList', debounceClient],
    queryFn: () => clientService.clientList({ searchKey: clientSearch }),
    placeholderData: keepPreviousData,
  });

  // get all template
  const { data: templateData, isLoading: isTemplateLoading } = useQuery({
    queryKey: ['template', debouncedTemplate],
    queryFn: () =>
      templateService.getTemplateList({
        searchKey: templateSearch,
        select: ['id', 'name'],
      }),
    select: (data) => {
      return data?.data?.rows;
    },
  });

  // input change of fields
  const handleChange = (event) => {
    const { name, value, type } = event.target;

    // handleChange for radio button
    if (type === 'radio') {
      setProject((prev) => ({
        ...prev,
        [name]:
          value === 'none'
            ? projectConstants.NONE
            : value === 'sprint'
            ? projectConstants.SPRINT
            : projectConstants.MILESTONE,
      }));
      return;
    }

    if (name === 'allocated_users') {
      setProject((prev) => ({
        ...prev,
        [name]: value.map((item) => item),
      }));
      return;
    }

    if (name === 'template_id') {
      if (project?.allow_multiple_template) {
        setProject((prev) => ({
          ...prev,
          [name]: value?.map((item) => item),
        }));
      } else {
        setProject({
          ...project,
          [name]: [value], // Ensure template_id is an array with a single element
        });
      }
      return;
    }

    setProject({
      ...project,
      [name]: value,
    });
  };

  /***
   * To scroll to the first found error element. 'help-block' class is considered as the error element. It will scroll to the first found parent of help-block class.
   */
  const scrollToErrorElement = () => {
    window.requestAnimationFrame(() => {
      const element = document.querySelector('.error-block');
      if (!element) {
        return;
      }
      const parentElement = element.parentElement;
      parentElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    });
  };

  // save project

  const handleSaveProject = async (data) => {
    await projectService.projectAdd(data);
    makeAlert('Success');
  };
  // --react query for mutation function
  const {
    mutateAsync: handleMutate,
    isPending,
    isPaused, //-- true when network offline mode
  } = useMutation({
    // networkMode: 'online',
    mutationFn: handleSaveProject,
    mutationKey: ['save-project'],
    onSuccess: (data) => {
      setSubmitted(false);
      setIsLoading(false);
      console.log(data, 'data');
      if (id) {
        navigate(`/${RT.PROJECT_LIST}?pageNo=${searchParams.get('pageNo') ?? 1}`);
      } else {
        navigate(`/${RT.PROJECT_LIST}`);
      }
    },
  });

  //-- for preventing multiple calls and when internet offline
  const saveProject = (payload) => {
    if (isPaused) {
      makeAlert('No internet');
      return;
    }
    if (isPending) {
      return;
    }
    handleMutate(payload);
  };

  // handleChange image upload
  const handleImageUpload = (file, name, compressed, compressedBlob) => {
    setProject((prev) => ({
      ...prev,
      [name]: file.pop(),
      compressed_logo: compressed[0],
    }));

    setCompressed(compressedBlob);
  };

  const handleToggleTemplate = (event) => {
    const { name, checked } = event.target;
    setProject((prev) => ({
      ...prev,
      [name]: checked,
    }));
    return;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true);
    setIsLoading(true);
    const startDate = getStartDateMilliSecond(project?.start_date);
    const endDate = getStartDateMilliSecond(project?.end_date);
    const project_owner_id = project?.project_owner_id?.id;
    const allocated_users = project?.allocated_users?.map((item) => parseInt(item?.id));
    const team_lead = project?.team_lead?.map((item) => parseInt(item?.id));
    const client_id = project?.client_id?.id;
    const template_id = project?.template_id?.map((item) => parseInt(item?.id));

    let payload = {
      ...project,
      start_date: startDate,
      end_date: endDate,
      project_owner_id: project_owner_id,
      allocated_users: allocated_users,
      client_id: client_id,
      template_id: template_id,
      team_lead: team_lead,
    };

    if (!hasExist(PROJECT_IDS.END_DATE)) {
      delete payload.end_date;
    }
    if (!hasExist(PROJECT_IDS.START_DATE)) {
      delete payload.start_date;
    }
    const schema = projectSchema();
    const validationResult = schema.safeParse(payload);

    if (!validationResult.success) {
      // makeAlert(' The data entered is incorrect. Please check your input and try again.');
      setIsLoading(false);
      scrollToErrorElement();
      return;
    }

    if (project.id && !editProjectData.group_required && project.group_required) {
      payload.enabled_when_edit = true;
    }

    try {
      saveProject(payload);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    } finally {
      setSubmitted(false);
    }
  };

  const handleToggle = () => {
    setProject((prev) => ({ ...prev, group_required: !prev.group_required }));
  };

  const handleToggleShared = () => {
    setProject((prev) => ({ ...prev, is_shared: !prev.is_shared }));
  };
  const handleTeamLead = (user) => {
    const userId = parseInt(user?.id);

    setProject((prev) => {
      // Check if user is already in the team_lead array
      if (prev?.team_lead?.some((item) => parseInt(item.id) === userId)) {
        // Remove the user from the team_lead array
        return {
          ...prev,
          team_lead: prev.team_lead?.filter((item) => parseInt(item.id) !== userId),
        };
      } else {
        // Add the user to the team_lead array
        return {
          ...prev,
          team_lead: [...(prev?.team_lead ?? []), user],
        };
      }
    });
  };

  const handleAllocatedUserSave = (list) => {
    setProject((prev) => ({
      ...prev,
      allocated_users: list,
    }));
  };

  let imageUrl = compressed
    ? compressed
    : project?.compressed_logo
    ? apiConstants.imgUrlS3 + project.compressed_logo
    : project?.logo
    ? apiConstants.imgUrlS3 + project?.logo
    : Images.ImagePreview;

  return (
    <div>
      {/* component will active when error occurs   */}
      <ErrorComponent
        error={
          projectOwnerError?.message ||
          userError?.message ||
          clientError?.message ||
          projectOwnerError?.message
        }
      />
      {/* url section  */}
      <div className='d-flex ptr breadcrumbs url-inner-navigation ' style={{ zIndex: 1 }}>
        <div className='back-button-container' onClick={() => navigate(-1)}>
          <ArrowLeftIcon strokeWidth={1.5} size={26} color='#87909E' />
        </div>
        <div className='d-flex align-items-center '>
          <IconLabelSet
            label={'Home'}
            fontWeight={400}
            icon={<Home strokeWidth={1.5} size={16} />}
            onClick={() => navigate('/')}
          />
          <img className='slash-img-icon-size ms-1 mt-1' src='/icons/Lineicon.png' alt='' />
          <IconLabelSet
            label={` ${LABEL_OF_PROJECT}`}
            fontWeight={400}
            icon={<ProjectIcon height={14} width={14} color='#2A2E34' />}
            onClick={() => navigate(`/${RT.PROJECT_LIST}?pageNo=1`)}
          />
          <img className='slash-img-icon-size ms-1 mt-1' src='/icons/Lineicon.png' alt='' />
          <IconLabelSet
            label={`${id > 0 ? 'Edit' : 'Add'} ${LABEL_OF_PROJECT}`}
            fontWeight={500}
            color={`#2A2E34`}
            onClick={() => navigate(`/${RT.PROJECT_ADD}/${project?.id}`)}
          />
        </div>
      </div>

      {/* form section  */}
      <div className='project-table-container  ' style={{ padding: 0 }}>
        <div
          className='d-flex align-items justify-content-between '
          style={{ padding: '8px 20px', height: 40, background: '#fff' }}
        >
          <span className='fz-16px-rem' style={{ fontWeight: 600 }}>
            {id == 0 ? 'Add' : 'Edit'} {LABEL_OF_PROJECT}
          </span>
          {id == 0 && (
            <ToolTip tooltipText={'Clear'} isModern>
              <span
                className='clear-button'
                onClick={() => {
                  setProject(ProjectState);
                  setCompressed(null);
                  setSubmitted(false);
                  setIsLoading(false);
                }}
              >
                Clear
              </span>
            </ToolTip>
          )}
        </div>
        <div className='project-separation' />
        <div>
          <form onSubmit={handleSubmit}>
            <div className='project-add-container breadcrumbs-page'>
              <div className='col-md-8 project-form-separation '>
                {/* logo upload and preview section  */}
                {hasExist(PROJECT_IDS.IMAGE) && (
                  <div className='position-relative' style={{ width: 'fit-content' }}>
                    <img src={imageUrl} alt='project-logo' className='project-image-outer' />
                    <CustomFileUpload
                      name='logo'
                      accept='image'
                      isCompressed={true}
                      isMulti={false}
                      handleOK={handleImageUpload}
                      CustomView={
                        <div className='logo-add-plus-btn-bg cursor-pointer'>
                          <Plus color='white' size={17} />
                        </div>
                      }
                    />
                  </div>
                )}
                <div className='project-input-row mt-3'>
                  <div className='col'>
                    <div>
                      <label className='mb-1 fz-13px-rem '>
                        {LABEL_OF_PROJECT} Name<span className='field-required'> *</span>
                      </label>
                      <input
                        type='text'
                        className='input-box'
                        style={{ height: '34px' }}
                        name='name'
                        value={project?.name}
                        placeholder={`Enter ${LABEL_OF_PROJECT} name`}
                        onChange={(event) => {
                          handleChange(event);
                        }}
                      />
                      <ErrorBlock
                        errorMsg={PROJECT_REQUIRED_FIELD_MSG.NAME}
                        hasError={submitted && project?.name.toString().trim() === ''}
                      />
                    </div>
                  </div>
                  {hasExist(PROJECT_IDS.DESCRIPTION) && (
                    <div className='col'>
                      <div>
                        <label className='mb-1 fz-13px-rem '>Description</label>
                        <input
                          type='text'
                          className='input-box'
                          style={{ height: '34px' }}
                          name='note'
                          value={project?.note}
                          placeholder={`Enter ${LABEL_OF_PROJECT} description `}
                          onChange={(event) => {
                            handleChange(event);
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>

                {(hasExist(PROJECT_IDS.COST) || hasExist(PROJECT_IDS.ESTIMATED_COST)) && (
                  <div className='project-input-row'>
                    {hasExist(PROJECT_IDS.COST) && (
                      <div className='col'>
                        <div>
                          <label className='mb-1 fz-13px-rem '>{LABEL_OF_PROJECT} Cost</label>
                          <input
                            type='number'
                            name='project_cost'
                            className='input-box'
                            style={{ height: '34px' }}
                            value={project?.project_cost}
                            placeholder={`Enter  ${LABEL_OF_PROJECT}  cost`}
                            onChange={(event) => {
                              handleChange(event);
                            }}
                          />
                        </div>
                      </div>
                    )}

                    {hasExist(PROJECT_IDS.ESTIMATED_COST) && (
                      <div className='col'>
                        <label className='mb-1 fz-13px-rem '>Estimated Cost</label>
                        <input
                          type='number'
                          className='input-box'
                          style={{ height: '34px' }}
                          name='estimated_cost'
                          value={project?.estimated_cost}
                          placeholder='Enter estimated cost '
                          onChange={(event) => {
                            handleChange(event);
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}

                <div className='project-input-row'>
                  {(hasExist(PROJECT_IDS.START_DATE) || hasExist(PROJECT_IDS.END_DATE)) && (
                    <div className='col'>
                      <div className='row'>
                        {/* {hasExist(PROJECT_IDS.START_DATE) && ( */}
                        <div className='col-md-6'>
                          <label className='mb-1 fz-13px-rem '>Start-Date</label>
                          <input
                            type='date'
                            className='input-box'
                            style={{ height: '34px' }}
                            placeholder={today}
                            value={moment(project?.start_date).format('YYYY-MM-DD')}
                            name='start_date'
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />
                          {/* <ErrorBlock
                              errorMsg={PROJECT_REQUIRED_FIELD_MSG.START_DATE}
                              hasError={submitted && !project?.start_date && hasExist(PROJECT_IDS.START_DATE)}
                            /> */}
                        </div>
                        {/* )} */}
                        {/* {hasExist(PROJECT_IDS.END_DATE) && ( */}
                        <div className='col-md-6'>
                          <label className='mb-1 fz-13px-rem '>End-Date</label>
                          <input
                            type='date'
                            placeholder={today}
                            min={project?.start_date}
                            value={moment(project?.end_date).format('YYYY-MM-DD')}
                            name='end_date'
                            className='input-box'
                            style={{ height: '34px' }}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />
                          {/* <ErrorBlock
                              errorMsg={PROJECT_REQUIRED_FIELD_MSG.END_DATE}
                              hasError={submitted && !project?.end_date && hasExist(PROJECT_IDS.END_DATE)}
                            /> */}
                          <ErrorBlock
                            errorMsg={PROJECT_REQUIRED_FIELD_MSG.DATE_RANGE}
                            hasError={
                              submitted &&
                              project?.end_date < project?.start_date &&
                              hasExist(PROJECT_IDS.START_DATE) &&
                              hasExist(PROJECT_IDS.END_DATE)
                            }
                          />
                        </div>
                        {/* )} */}
                      </div>
                    </div>
                  )}
                  <div className='col'>
                    <div className='row'>
                      <div className='my-1 fz-13px-rem '>
                        {LABEL_OF_PROJECT} Owner
                        <span className='field-required'> *</span>
                      </div>

                      <ReactSelect
                        placeholder={`Choose  ${LABEL_OF_PROJECT}  owner`}
                        name='project_owner_id'
                        styles={DropDownStyle}
                        components={{ DropdownIndicator: CustomDropdownIndicator }} // Use the custom dropdown indicator here
                        menuPortalTarget={document.getElementById('MODAL')}
                        formatOptionLabel={(data, metaData) => (
                          <UserDropDown data={data} metaData={metaData} hideDesignation={true} />
                        )}
                        getOptionValue={(option) => {
                          return parseInt(option?.id);
                        }}
                        // menuPlacement='top'
                        onInputChange={(inputString) => {
                          setProjectOwnerSearch(inputString);
                        }}
                        filterOption={null}
                        inputValue={projectOwnerSearch}
                        options={projectOwnerUserData?.data?.rows}
                        onChange={(selectedOption) => {
                          handleChange({
                            target: {
                              name: 'project_owner_id',
                              value: selectedOption,
                            },
                          });
                        }}
                        isLoading={isProjectOwnerLoading}
                        value={project?.project_owner_id}
                      />

                      <ErrorBlock
                        errorMsg={PROJECT_REQUIRED_FIELD_MSG.PROJECT_OWNER}
                        hasError={submitted && !project?.project_owner_id}
                      />
                    </div>
                  </div>
                </div>

                <div className='row mt-1' style={{ fontWeight: 500 }}>
                  <div className='col-md-6'>
                    <div className='d-flex justify-content-between'>
                      <div className='my-1 fz-13px-rem '>{LABEL_OF_CLIENT}</div>
                      <ToolTip tooltipText={`Add ${LABEL_OF_CLIENT}`} isModern popOver>
                        <div
                          className='contact-add-icon pointer'
                          onClick={() => setContactAddModal(true)}
                        >
                          <Plus size={14} />
                        </div>
                      </ToolTip>
                    </div>
                    <ReactSelect
                      name='client_id'
                      menuPlacement='top' // Add this prop to position the menu above the field
                      placeholder={`Choose  ${LABEL_OF_CLIENT} `}
                      styles={DropDownStyle}
                      components={{ DropdownIndicator: CustomDropdownIndicator }} // Use the custom dropdown indicator here
                      getOptionLabel={(option) => {
                        return option?.name;
                      }}
                      menuPortalTarget={document.getElementById('MODAL')}
                      getOptionValue={(option) => {
                        return option?.id;
                      }}
                      onInputChange={(inputString) => {
                        setClientSearch(inputString);
                      }}
                      filterOption={null}
                      inputValue={clientSearch}
                      options={clientData?.data?.rows}
                      onChange={(selectedOption) => {
                        handleChange({
                          target: {
                            name: 'client_id',
                            value: selectedOption,
                          },
                        });
                      }}
                      value={project?.client_id}
                      isLoading={isClientLoading}
                      isClearable
                    />
                  </div>
                </div>

                <div className='row mt-2' style={{ fontWeight: 500 }}>
                  <div className='d-flex justify-content-between'>
                    <div className='my-1 fz-13px-rem '>
                      Template
                      <span className='field-required'> *</span>
                    </div>
                    <div className='d-flex align-items-center'>
                      <span style={{ marginRight: 7 }} className='fz-13px-rem '>
                        Allow multiple
                      </span>
                      <ToggleButton
                        id={'template'}
                        isChecked={project?.allow_multiple_template}
                        size={25}
                        handleToggle={(event) => {
                          handleToggleTemplate({
                            target: {
                              name: 'allow_multiple_template',
                              checked: event.target.checked,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>

                  <ReactSelect
                    name='template_id'
                    options={templateData}
                    placeholder='Choose Template'
                    styles={DropDownStyle}
                    components={{ DropdownIndicator: CustomDropdownIndicator }} // Use the custom dropdown indicator here
                    menuPlacement='top' // Add this prop to position the menu above the field
                    menuPortalTarget={document.getElementById('MODAL')}
                    getOptionLabel={(option) => option?.name}
                    getOptionValue={(option) => parseInt(option?.id)}
                    onInputChange={(inputString) => setTemplateSearch(inputString)}
                    isMulti={project?.allow_multiple_template}
                    inputValue={templateSearch}
                    filterOption={null}
                    value={project?.template_id}
                    onChange={(selectedOptions) =>
                      handleChange({
                        target: {
                          name: 'template_id',
                          value: selectedOptions,
                        },
                      })
                    }
                    isLoading={isTemplateLoading}
                  />
                  <ErrorBlock
                    errorMsg={PROJECT_REQUIRED_FIELD_MSG.TEMPLATE}
                    hasError={
                      submitted &&
                      (project?.template_id?.length === 0 || project?.template_id === null)
                    }
                  />
                </div>

                {/* project type - Radio buttons  */}
                <div className='row mt-3' style={{ fontWeight: 500 }}>
                  <div className=' fz-13px-rem'>Type</div>
                  <div>
                    <div
                      className='form-check form-check-inline fz-13px-rem '
                      style={{ width: 'fit-content' }}
                    >
                      <input
                        className='form-check-input'
                        type='radio'
                        name='project_type'
                        id='sprint'
                        onChange={() => {
                          handleChange({
                            target: {
                              name: 'project_type',
                              value: 'sprint',
                              type: 'radio',
                            },
                          });
                        }}
                        checked={project?.project_type == 1}
                      />
                      <label className='form-check-label' htmlFor='sprint'>
                        Sprint
                      </label>
                    </div>
                    <div
                      className='form-check form-check-inline fz-13px-rem '
                      style={{ width: 'fit-content' }}
                    >
                      <input
                        className='form-check-input'
                        type='radio'
                        name='project_type'
                        id='milestone'
                        checked={project?.project_type == 2}
                        onChange={() => {
                          handleChange({
                            target: {
                              name: 'project_type',
                              value: 'milestone',
                              type: 'radio',
                            },
                          });
                        }}
                      />
                      <label className='form-check-label' htmlFor='milestone'>
                        Milestone
                      </label>
                    </div>
                    <div
                      className='form-check form-check-inline fz-13px-rem'
                      style={{ width: 'fit-content' }}
                    >
                      <input
                        className='form-check-input'
                        type='radio'
                        name='project_type'
                        id='none'
                        checked={project?.project_type == 0}
                        onChange={() => {
                          handleChange({
                            target: {
                              name: 'project_type',
                              value: 'none',
                              type: 'radio',
                            },
                          });
                        }}
                      />
                      <label className='form-check-label' htmlFor='none'>
                        None
                      </label>
                    </div>
                  </div>
                </div>

                {/* end of project type  */}

                <div style={{ paddingBottom: 6 }}>
                  <div className='row mt-3' style={{ fontWeight: 500 }}>
                    {/* group required */}
                    {(!id || (id && !editProjectData.group_required)) && (
                      <div className='group-confirm-contain'>
                        <span>Create group for discussion</span>
                        <ToggleButton
                          key={1}
                          id={1}
                          isChecked={project?.group_required}
                          handleToggle={handleToggle}
                          size={25}
                        />
                      </div>
                    )}
                    {/* end group required */}
                  </div>

                  {/* activate  */}
                  {id > 0 && (
                    <div className='row mt-3' style={{ fontWeight: 500 }}>
                      <div className='d-flex align-items-center gap-2'>
                        <span className='fz-13px-rem'>Activate </span>
                        <ToggleButton
                          id={'status'}
                          isChecked={project?.status === 1}
                          size={25}
                          handleToggle={() =>
                            handleChange({
                              target: { name: 'status', value: project?.status === 1 ? -1 : 1 },
                            })
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='col-md-4 ' style={{ padding: '0 20px 0 20px' }}>
                <div className='d-flex justify-content-between mt-2'>
                  <span className='fz-13px-rem ' style={{ fontWeight: 500 }}>
                    Allocated Users
                  </span>

                  <span
                    className='fz-13px-rem cursor-pointer'
                    ref={userListRef}
                    onClick={() => {
                      setUserListPopup(true);
                    }}
                    style={{ fontWeight: 500, position: 'relative' }}
                  >
                    <ToolTip tooltipText={'Add Users'} isModern hideTooltip={userListPopup}>
                      <Plus size={14} /> Add
                    </ToolTip>
                    {userListPopup && (
                      <UserListSelectionPopup
                        userList={userData?.data?.rows}
                        setSearchKey={setUserSearch}
                        searchKey={userSearch}
                        handleSave={handleAllocatedUserSave}
                        selectedUsers={project?.allocated_users}
                        handleClose={(event) => {
                          setUserSearch('');
                          event.stopPropagation();
                          setUserListPopup(false);
                        }}
                        isLoading={isUserLoading}
                      />
                    )}
                  </span>
                </div>

                <ErrorBlock
                  errorMsg={PROJECT_REQUIRED_FIELD_MSG.ALLOCATED_USERS}
                  hasError={
                    submitted &&
                    (project?.allocated_users?.length === 0 || project?.template_id === null)
                  }
                />

                {/* allocated user list  */}
                <div className='mt-3'>
                  {project?.allocated_users?.map((user) => {
                    const isMale = user?.gender === 1;
                    const isNeutral = user?.gender === 3;
                    const url = user?.compressed_image_url
                      ? user?.compressed_image_url
                      : user?.image_url;
                    const finalUrl = url ? apiConstants.imgUrlS3 + url : null;
                    const fullName = fullNameUser(user?.first_name, user?.last_name);

                    return (
                      <div key={user?.id} className='user-list-item-display fz-13px-rem  my-2'>
                        <div className='d-flex align-items-center justify-content-between'>
                          <div className='d-flex align-items-center' style={{ width: '65%' }}>
                            <CircularAvatar
                              size={25}
                              className='fz-none zoom-in employee-img-with-low-border'
                              source={finalUrl}
                              isMale={isMale}
                              isNeutral={isNeutral}
                            />
                            <span className='name-overflow'>{fullName}</span>
                          </div>
                          <ToolTip
                            isModern
                            tooltipText={'Set as team lead'}
                            hideTooltip={project?.team_lead?.some(
                              (item) => parseInt(item?.id) === parseInt(user?.id),
                            )}
                          >
                            {/* <div style={{marginRight:10}}> */}
                            <ToggleButton
                              key={user?.id}
                              id={user?.id}
                              isChecked={project?.team_lead?.some(
                                (item) => parseInt(item?.id) === parseInt(user?.id),
                              )}
                              handleToggle={() => handleTeamLead(user)}
                              size={25}
                            />
                            {/* </div> */}
                          </ToolTip>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className='project-separation' />
            {/* footer section  */}

            <div style={{ padding: '15px 20px' }} className='d-flex justify-content-between'>
              {/* task visibility of project owner  */}

              <div className='group-confirm-contain' style={{ fontWeight: 500 }}>
                <span>Shared with all</span>
                <ToggleButton
                  key={1}
                  id={'is_shared'}
                  isChecked={project?.is_shared}
                  handleToggle={() => {
                    handleToggleShared();
                  }}
                  size={25}
                />
              </div>

              <div className=' d-flex gap-2'>
                <Button.Container isDisabled={isLoading} type='submit'>
                  <Button.Title title={'Save'} />
                  <Button.Loading isLoading={isLoading} />
                </Button.Container>
              </div>
            </div>
          </form>
        </div>
      </div>
      {addContactModal && (
        <Modal.Container handleClose={() => setContactAddModal(false)}>
          <Modal.View className={'modal_view_label'}>
            <Modal.Head
              Heading={`Add ${LABEL_OF_CLIENT}`}
              handleClose={() => {
                setContactAddModal(false);
              }}
            />
            <ClientAdd
              afterClientAdd={(data) => {
                if (data?.id) {
                  handleChange({
                    target: {
                      name: 'client_id',
                      value: { id: data?.id, name: data?.name },
                    },
                  });
                }
              }}
              handleClose={() => {
                setContactAddModal(false);
                clientRefetch();
              }}
            />
          </Modal.View>
        </Modal.Container>
      )}
    </div>
  );
}
