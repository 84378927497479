import { X } from 'lucide-react';
import React, { useState } from 'react';
import { MY_TEAM_REQUIRED_FIELD_MSG } from '../Myteam/MyTeamConstant';

const EmailInput = ({ onChangeData = () => {}, isEmailValidate = false, error, setError }) => {
  const [emails, setEmails] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [editingIndex, setEditingIndex] = useState(null); // Tracks the index of the email being edited
  const [editingValue, setEditingValue] = useState(''); // Tracks the edited email value

  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const trimmedValue = inputValue.trim();
      if (trimmedValue) {
        if (isEmailValidate && isValidEmail(trimmedValue)) {
          setEmails([...emails, trimmedValue]);
          setInputValue('');
          onChangeData([...emails, trimmedValue]);
          setError(''); // Clear error if valid
        } else if (!isEmailValidate) {
          setEmails([...emails, trimmedValue]);
          setInputValue('');
          onChangeData([...emails, trimmedValue]);
          setError(''); // Clear error if valid
        } else {
          setError(MY_TEAM_REQUIRED_FIELD_MSG.INVALID_EMAIL); // Set error for invalid email
        }
      }
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const removeEmail = (index) => {
    const filteredData = emails.filter((_, i) => i !== index);
    setEmails(filteredData);
    onChangeData(filteredData);
  };

  const handleEditEmail = (index) => {
    setEditingIndex(index);
    setEditingValue(emails[index]);
  };

  const handleEditKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const trimmedValue = editingValue.trim();
      if (trimmedValue && (!isEmailValidate || isValidEmail(trimmedValue))) {
        const updatedEmails = emails.map((email, i) => (i === editingIndex ? trimmedValue : email));
        setEmails(updatedEmails);
        onChangeData(updatedEmails);
        setEditingIndex(null);
        setEditingValue('');
        setError(''); // Clear error if valid
      } else {
        setError(MY_TEAM_REQUIRED_FIELD_MSG.INVALID_EMAIL); // Set error for invalid email
      }
    }
  };

  const handleBlur = () => {
    if (editingIndex !== null) {
      setEditingIndex(null);
      setEditingValue('');
    }
  };

  return (
    <>
      <div className='email-input-container'>
        {emails.map((email, index) => (
          <div key={index} className='entered-email-container'>
            {editingIndex === index ? (
              <input
                type='text'
                value={editingValue}
                onChange={(e) => setEditingValue(e.target.value)}
                onKeyDown={handleEditKeyDown}
                onBlur={handleBlur}
                className='edit-email-input'
                autoFocus
              />
            ) : (
              <>
                <span className='entered-mail-text' onClick={() => handleEditEmail(index)}>
                  {email}
                </span>
                <button onClick={() => removeEmail(index)} className='remove-mail-icon'>
                  <X size={12} />
                </button>
              </>
            )}
          </div>
        ))}
        <input
          type='text'
          value={inputValue}
          onChange={(e) => {
            if (error) {
              setError('');
            }
            setInputValue(e.target.value);
          }}
          onBlur={() => handleInputKeyDown({ key: 'Enter', preventDefault: () => {} })}
          onKeyDown={handleInputKeyDown}
          placeholder='Add email...'
          className='mail-input'
        />
      </div>
      {isEmailValidate && error && <div className='error-block'>{error}</div>}
    </>
  );
};

export default EmailInput;
