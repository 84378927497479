import moment from 'moment';
// import { SHARED_WITH_ALL_OPTION } from '../File/FileSystemConstant';
import { projectConstants } from '../_constants/project.constant';
import { TEMPLATE_VISIBLE_ALL_OPTION, VALIDATION_VALUES } from '../_constants/template.constants';
import { SHARED_WITH_ALL_OPTION } from '../File/FileSystemConstant';
import { objectiveVisibility, progressType } from '../OKR/okrConstants';
import { EDIT_TASK_SETTINGS } from '../Settings/SettingsLabels';
import { STATUS } from '../Task/taskConstants';

let today = moment().format('YYYY-MM-DD');
const currentTime = moment().format('HH:mm'); // Get current time in HH:mm format

export const OrganizationState = {
  name: '',
  address: '',
  city: '',
  pin_code: '',
  phone: '',
  email: '',
  website: '',
  logo: '',
  status: 1,
  is_default: 0,
  company_time_zone: null,
  open_time: '',
  close_time: '',
};

export const EmployeeState = {
  first_name: '',
  last_name: '',
  gender: null, // 1 : male , 2 : female , 3 : others
  department_id: 0,
  email_id: '',
  phone_no: '',
  dob: '',
  role: 0,
  // username: '',
  // password: '',
  reported_users: [],
  image_url: '',
  address: '',
  employee_code: '',
  // office_number: '',
  // office_mail: '',
  designation_id: 0,
  custom_fields: {},
  compressed_image_url: '',
  // reporting_user_options : {id:'',name:'',is_immediate:0,is_enable_notification:0}
};

export const BranchState = {
  name: '',
  company_id: '',
  branch_time_zone: '',
  open_time: '',
  close_time: '',
};

export const MilestoneState = {
  name: '',
  duration: 0,
  start_date: moment().valueOf(),
  end_date: '',
  goal: undefined,
};
export const OkrObjectiveState = {
  assignee_id: '',
  name: '',
  note: '',
  interval: 0,
  task_start_date: moment().valueOf(),
  task_end_date: '',
  visibility_type: objectiveVisibility.All,
  visibility_team: [],
  objective_type: 1,
  // visibility_user: [],
  okr_progress_type: progressType.basedOnChild,
  manual_progress_type: 0,
  progress_measure_end: 0,
  progress_measure_start: 0,
  okr_progress: 0,
  manual_current_progress: 0,
};
export const OkrChildState = {
  objective_type: 0,
  assignee_id: '',
  name: '',
  note: '',
  visibility_team: [],
  interval: 0,
  task_start_date: moment().valueOf(),
  task_end_date: '',
  okr_progress_type: progressType.basedOnChild,
  manual_progress_type: 0,
  progress_measure_end: 0,
  progress_measure_start: 0,
  okr_progress: 0,
  manual_current_progress: 0,
};

export const LabelState = {
  name: '',
  label_color: '#000000',
  text_color: '#000000',
};

export const DepartmentState = {
  name: '',
  company_id: 0,
  status: 1,
  is_department: 1,
  department_id: null,
  priority: null,
};

export const ClientState = {
  name: '',
  email: '',
  phone: '',
  company_name: '',
  description: '',
  status: 1,
  logo: '',
  shared_with_user: [],
  shared_with_team: [],
  compressed_logo: '',
};
export const ProjectState = {
  name: '',
  start_date: today,
  end_date: today,
  client_id: 0,
  project_type: projectConstants.NONE,
  project_owner_id: 0,
  status: 1,
  logo: '',
  compressed_logo: '',
  note: '',
  company_id: 0,
  estimated_cost: 0,
  project_cost: 0,
  allocated_users: [],
  template_id: [],
  allow_multiple_template: false,
  group_required: false,
  is_shared: false,
  team_lead: [],
};

// const validationInit = {
//   taskName: 1,
//   description: 1,
//   startDate: 1,
//   endDate: 1,
//   startTime: 1,
//   endTime: 1,
//   requestedHours: 1,
//   priority: 1,
//   assignedUser: 1,
//   verifyUser: 1,
//   subtasks: 1,
// };

const validationInit = {
  name: VALIDATION_VALUES.REQUIRED,
  note: VALIDATION_VALUES.NOT_REQUIRED,
  task_start_date: VALIDATION_VALUES.NOT_REQUIRED,
  task_end_date: VALIDATION_VALUES.NOT_REQUIRED,
  start_time: VALIDATION_VALUES.NOT_REQUIRED,
  end_time: VALIDATION_VALUES.NOT_REQUIRED,
  expected_hours: VALIDATION_VALUES.REQUIRED,
  priority: VALIDATION_VALUES.NOT_REQUIRED,
  assignee_id: VALIDATION_VALUES.REQUIRED,
  verifying_users: VALIDATION_VALUES.NOT_REQUIRED,
  subtasks: VALIDATION_VALUES.NOT_REQUIRED,
  client_id: VALIDATION_VALUES.NOT_REQUIRED,
};

export const templateState = {
  name: '',
  description: '',
  user_id: undefined,
  expected_minutes: '', //Will be removed onSubmit, is a temporary data holder
  expected_hours: '',
  template_visible_users: [TEMPLATE_VISIBLE_ALL_OPTION],
  template_visible_groups: [],
  verifying_users: null,
  task_visible_users: null,
  is_private: false,
  task_visibility_type: 0,
  is_verification_required: true,
  related_tasks: [],
  subtasks: [],
  labels: null,
  type: 1,
  share_updates: 0,
  assign_to_main: 0,
  assign_user_scope: 0,
  is_verify_user_changeable: 0,
  show_status_changes_in_chat: false,
  task_type: 1,
  include_in_menu: false,
  is_unlisted: false,
  menu_title: undefined,
  status_group: null,
  validation_fields: validationInit,
  has_time: false,
  start_time: 0,
  end_time: 0,
  task_edit_permissions: EDIT_TASK_SETTINGS,
};

export const taskState = {
  name: '',
  note: '',
  project_id: null,
  milestone_id: 0,
  expected_hours: 1,
  expected_minutes: 0,
  task_start_mode: 1,
  preceding_task_id: 0,
  custom_start_time: 0,
  custom_start_days: 0, //Temp data holder for custom_start_time, will be removed on submit
  custom_start_hours: 0, //Temp data holder for custom_start_time, will be removed on submit
  task_start_date: moment().valueOf(),
  task_end_date: moment().valueOf(),
  priority: null,
  order_seq: null,
  has_time: false,
  user_id: 0,
  assignee_id: 0,
  template_id: 0,
  label: [],
  subtasks: [],
  relatedTasks: [],
  template: {},
  verifying_users: [],
  client_id: null,
};

export const subtaskState = {
  content: '',
  name: '',
  user_id: 0,
  task_id: 0,
  start_date_milli: moment().valueOf(),
  end_date_milli: moment().valueOf(),
};

export const folderState = {
  name: '',
  shared_with_user: [SHARED_WITH_ALL_OPTION.USER],
  shared_with_team: [SHARED_WITH_ALL_OPTION.TEAM],
};

export const fileState = {
  name: '',
  description: '',
  document_type_id: 0,
  owner_id: 0,
  location: '',
  issued_date: today,
  expiry_date: '',
  client_id: 0,
  shared_with_user: [SHARED_WITH_ALL_OPTION.USER],
  shared_with_team: [SHARED_WITH_ALL_OPTION.TEAM],
  shared_with_project: [SHARED_WITH_ALL_OPTION.PROJECT],
  file_url: [],
  folder_id: 0,
  file_type: 0,
};

export const fileHistoryState = {
  date: today,
  time: currentTime,
  sender_id: 0,
  receiver_id: 0,
};

export const taskFilter = {
  task_start_date: '',
  task_end_date: '',
  template_id: [],
  project_id: [],
  client_id: [],
  priority_id: [],
  label_id: [],
  user_id: '',
  task_status: STATUS.ACTIVE,
};

export const userFilter = {
  department_id: 0,
  designation_id: 0,
  reported_users_ids: 0,
};

export const projectFilter = {
  project_owner: 0,
  status: null,
  start_date: '',
  end_date: '',
};
