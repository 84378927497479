/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';

export const useAudioRecorder = ({
  isNeedTranscript = false,
  isAutoStopBased = true,
  onStop = () => {},
}) => {
  const [audioAmplitude, setAudioAmplitude] = useState(null);
  const recordingRef = useRef(false);

  const mediaRecorderRef = useRef(null); // Use useRef for mediaRecorder
  const silenceTimerRef = useRef(null); // Use useRef for silenceTimer
  const [recording, setRecording] = useState(false);
  const [transcription, setTranscription] = useState('');
  const [isPermitted, setIsPermitted] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [isTranscriptionAvailable, setIsTranscriptionAvailable] = useState(true);

  const [audioContext, setAudioContext] = useState(null);

  // Thresholds and timings for detecting silence
  const silenceThreshold = 0.01; // Adjust this threshold to be more or less sensitive to silence
  const silenceDuration = 2000; // Duration (in milliseconds) of silence before stopping

  // Cleanup function to stop recording and release media resources
  useEffect(() => {
    return () => {
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  // Cleanup function to stop recording and release media resources
  useEffect(() => {
    return () => {
      clearMicrophone(); // Cleanup when the component unmounts
    };
  }, []);

  // Function to convert audio blob to base64
  const audioBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(',')[1]);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(blob);
    });
  };

  const clearMicrophone = () => {
    console.log('clearMicrophone called', mediaRecorderRef?.current);
    if (mediaRecorderRef.current) {
      console.log('Stopping media tracks...');
      mediaRecorderRef.current.stream.getTracks().forEach((track) => track.stop());
      mediaRecorderRef.current = null;
    } else {
      console.log('No media recorder to clean up.');
    }

    if (audioContext) {
      console.log('Closing audio context...');
      audioContext.close();
      setAudioContext(null);
    } else {
      console.log('No audio context to close.');
    }
  };

  // Check for microphone permission and request if needed
  const checkMicrophonePermission = async () => {
    try {
      const permissionStatus = await navigator.permissions.query({ name: 'microphone' });
      if (permissionStatus.state === 'granted') {
        setIsPermitted(true);
        return true;
      } else if (permissionStatus.state === 'prompt') {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        if (stream) {
          setIsPermitted(true);
          stream.getTracks().forEach((track) => track.stop());
          return true;
        }
      }
      setIsPermitted(false);
      setError('Microphone permission denied or not available');
      return false;
    } catch (err) {
      console.error('Permission error:', err);
      setError('Microphone permission denied or not available');
      setIsPermitted(false);
      return false;
    }
  };

  const startRecording = async () => {
    try {
      setTranscription('');
      setError(null); // Reset any previous errors

      const hasPermission = await checkMicrophonePermission();
      if (!hasPermission) {
        return;
      }

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      let analyser, dataArray, bufferLength;

      const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
      //   await audioCtx.resume(); // Resume the audio context
      setAudioContext(audioCtx);

      const source = audioCtx.createMediaStreamSource(stream);
      analyser = audioCtx.createAnalyser();
      analyser.fftSize = 2048;
      source.connect(analyser);

      bufferLength = analyser.frequencyBinCount;
      dataArray = new Uint8Array(bufferLength);

      recorder.start();
      console.log('Recording started');

      // Store the media recorder in the ref
      mediaRecorderRef.current = recorder;

      // Event listener to handle data availability
      recorder.addEventListener('dataavailable', async (event) => {
        console.log('Data available event triggered', isNeedTranscript);
        const audioBlob = event.data;
        setAudioBlob(audioBlob); // Save the recorded audio file in state

        if (isNeedTranscript) {
          // If transcription is needed, convert the blob and send it to the transcription API
          const base64Audio = await audioBlobToBase64(audioBlob);

          try {
            setIsLoading(true);
            const response = await axios.post(
              ` https://speech.googleapis.com/v1/speech:recognize?key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
              {
                config: {
                  encoding: 'WEBM_OPUS',
                  sampleRateHertz: 48000,
                  languageCode: 'en-US',
                },
                audio: {
                  content: base64Audio,
                },
              },
            );

            if (response?.data?.results?.[0]?.alternatives?.[0]?.transcript) {
              setTranscription(response.data.results[0].alternatives[0].transcript);
              setIsTranscriptionAvailable(true);
            } else {
              console.log('No transcription results in the API response:', response.data);
              setTranscription('No transcription available');
              setIsTranscriptionAvailable(false);
            }
          } catch (apiError) {
            console.error('Error with Google Speech-to-Text API:', apiError);
            setError('Error with transcription service');
          } finally {
            setIsLoading(false);
          }
        }
      });

      if (isAutoStopBased) {
        // Monitor silence in the audio stream
        const detectSilence = () => {
          analyser.getByteFrequencyData(dataArray);

          // Calculate the average amplitude of the frequency data
          const averageAmplitude = dataArray.reduce((acc, val) => acc + val, 0) / bufferLength;
          setAudioAmplitude(averageAmplitude);

          console.log('HERE-->averageAmplitude', averageAmplitude);

          // If the amplitude is below the threshold, start counting silence
          if (averageAmplitude < silenceThreshold) {
            if (!silenceTimerRef.current) {
              const timer = setTimeout(() => {
                console.log('Silence detected, stopping recording...');
                stopRecording();
              }, silenceDuration);
              silenceTimerRef.current = timer;
            }
          } else {
            // If sound is detected, clear the silence timer
            if (silenceTimerRef.current) {
              clearTimeout(silenceTimerRef.current);
              silenceTimerRef.current = null;
            }
          }

          if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
            requestAnimationFrame(detectSilence);
          }
        };
        detectSilence();
      }

      setRecording(true);
      recordingRef.current = true;
      //   detectSilence(); // Start silence detection after recording starts
    } catch (permissionError) {
      console.error('Error getting user media:', permissionError);
      setIsPermitted(false);
      setError('Microphone permission denied or not available');
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      console.log('Recording stopped');
      setRecording(false);
      mediaRecorderRef.current.stream.getTracks().forEach((track) => track.stop());
      mediaRecorderRef.current = null; // Clear the media recorder reference
      recordingRef.current = false;
    }
    if (silenceTimerRef.current) {
      clearTimeout(silenceTimerRef.current); // Clear the silence timer if recording stops
      silenceTimerRef.current = null; // Clear the silence timer reference
    }
    onStop();
  };

  const retryPermission = async () => {
    const hasPermission = await checkMicrophonePermission();
    if (hasPermission) {
      startRecording();
    }
  };

  const startAndStopRecording = () => {
    if (recording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  console.log('audioAmplitude', audioAmplitude);

  return {
    startAndStopRecording,
    retryPermission,
    transcription,
    isPermitted,
    recording,
    isLoading,
    error,
    audioBlob,
    startRecording,
    stopRecording,
    checkMicrophonePermission,
    audioAmplitude,
    isTranscriptionAvailable,
    clearMicrophone,
  };
};
