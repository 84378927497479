import { useQuery } from '@tanstack/react-query';
import { Info } from 'lucide-react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../_components';
import { apiConstants, RT } from '../_constants';
import { userService } from '../_services';
import { organizationService } from '../_services/organization.service';
import { useUser } from '../Hook/useUser';
import ErrorBlock from '../Template/SubComponents/ErrorBlock';
import { Images } from '../Utils';
import { LoadingIcon } from '../Utils/SvgIcons';
import './invite.css';

export const InvitationWelcome = ({ setSearchParams, searchParams }) => {
  const [errorMsg, setErrorMsg] = useState('');
  const user = useUser();
  const navigate = useNavigate();

  const encodedData = Array.from(searchParams.entries()).find(([key]) => key !== 'view')?.[0];

  let decodedData = {};
  if (encodedData) {
    try {
      decodedData = JSON.parse(decodeURIComponent(encodedData));
    } catch (error) {
      console.error(' Error decoding data:', error);
      setErrorMsg(' Invalid data provided in the URL.');
      return; // Exit the function if decoding fails
    }
  } else {
    setErrorMsg(' No data provided in the URL.');
    return; // Exit if no encoded data is found
  }

  // Validate decodedData properties
  const { email_id, temp_pass, company_id } = decodedData;
  if (!email_id || !temp_pass || !company_id) {
    setErrorMsg(' Required data is missing in the URL.');
    return;
  }

  // const { makeAlert } = useContext(GlobalContext);
  const handleAccept = async () => {
    // Extract and decode the encoded data

    try {
      const response = await userService.inviteAccept({
        email_id,
        temp_password: temp_pass,
        company_id: company_id,

        userDetails: user ? { ...user } : null,
      });

      console.log(response, 'checkResponse');

      if (!response.error_code) {
        // Successfully accepted the invite
        if (response?.data?.is_new_user && response?.data?.navigate_to === 'register') {
          searchParams.set('view', 'signIn');
          setSearchParams(searchParams);
        } else if (response?.data?.is_old_user && response?.data?.navigate_to === 'login') {
          searchParams.delete('view'); // Remove 'view' if it's not required for navigation

          // Navigate to the login page with updated query string
          navigate(`/${RT.LOGIN}?${searchParams.toString()}`);
          // navigate(`/${RT.LOGIN}?`);
          // setErrorMsg(' You are already registered');
        }
      }
      if (response.error_code) {
        // Handle errors from the API
        setErrorMsg(response.message || ' An error occurred while accepting the invite.');
      }
    } catch (error) {
      console.error(' Error during invite acceptance:', error);
      setErrorMsg(error.message || ' Failed to process the invitation. Please try again later.');
    }
  };

  // fetch document type details
  const { data: companyLogo, isLoading } = useQuery({
    queryKey: ['cmp-data'],
    queryFn: () =>
      organizationService.getOrganizationDetails({ id: company_id, select: ['logo', 'id'] }),
    select: (data) => data?.data?.logo,
    enabled: Boolean(company_id),
  });

  let companyIcon = apiConstants.imgUrlS3 + companyLogo;

  return (
    <div className='invite-accept-body'>
      <div className='invite-accept-box'>
        <div className=''>
          {!isLoading ? (
            <>
              {companyLogo ? (
                <div className='invite-company-icon'>
                  <img src={companyIcon} alt='Company Icon' />
                </div>
              ) : (
                <div className='company_icon_contain ps-0'>
                  <img src={Images.CompanyLogo} alt='Default Company Logo' />
                </div>
              )}
            </>
          ) : (
            <LoadingIcon size={40} />
          )}
        </div>
        {/* <img src={companyIcon ? companyIcon : Images.CompanyLogo} height={20} width={110} /> */}
        <div className='invite-greeting '>
          Welcome to Company We’re excited to have you on board.
        </div>
        <div className='fz-13px' style={{ fontWeight: 500 }}>
          Please accept your invitation to continue adding employees and exploring Hidile’s powerful
          tools.
        </div>
        <Button.Container className={'blue-btn w-fit px-3'} handleOk={handleAccept}>
          <Button.Title title={'Accept Invitation '} />
        </Button.Container>
        <ErrorBlock
          errorMsg={
            <>
              <Info size={13} /> {errorMsg}
            </>
          }
          hasError={Boolean(errorMsg)}
        />
        <div className='invite-border-bottom' />
        <div className='fz-10px' style={{ fontWeight: 500 }}>
          Need help?{' '}
          <a
            href='mailto:hello@hidile.com'
            style={{ textDecoration: 'none', color: 'var(--accent-color)' }}
          >
            hello@hidile.com
          </a>
        </div>
      </div>
    </div>
  );
};
