/* eslint-disable no-unused-vars */
// AddWidgetModal.js
import { useQuery } from '@tanstack/react-query';
import React, { useContext, useEffect, useState } from 'react';
import { Button } from '../../_components';
import SearchableQuerySelect from '../../_components/SearchableQuerySelect';
import { CustomDropdownIndicator } from '../../_components/SelectionDropDownIcon';
import { CommonDropDownStyle } from '../../_constants';
import { miscService, projectService } from '../../_services';
import { GlobalContext } from '../../Utils';
import { LoadingIcon } from '../../Utils/SvgIcons';
import './widget.css';
import { widgetIcons } from './widgetconstants';

const AddWidgetModal = ({
  user = {},
  isOpen,
  onClose = () => {},
  onSubmit = () => {},
  isSaving,
  currentLayoutId = null,
  editWidgetData = {},
  isEdit = false,
}) => {
  console.log({ currentLayoutId, editWidgetData });

  const { makeAlert, globalSettings } = useContext(GlobalContext);

  const menuSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings?.name === 'menu-settings');

  const hasExistProject =
    menuSettings?.labels && menuSettings?.labels.some((label) => label.default_name === 'project');

  const [widget, setWidget] = useState({
    size: ['1', '2', '3'],
  });

  useEffect(() => {
    if (isEdit) {
      setWidget({
        ...widget,
        ...editWidgetData,
      });
    }
  }, [isEdit]);
  const {
    data: widgetList = [],
    isLoading: widgetListLoading,
    refetch,
  } = useQuery({
    queryKey: ['widgets-presets', currentLayoutId],
    queryFn: () => miscService.getAllWidgetPresets({ id: currentLayoutId }),
    select: (resp) => {
      const rers = resp?.data?.map((item) => {
        return {
          ...item,
          label: item?.title,
          value: item?.name,
        };
      });
      return rers;
    },
    enabled: user?.id ? true : false,
  });

  const {
    data: projectData,
    error,
    isLoading: projectLoading,
  } = useQuery({
    queryKey: ['projectList-widget'],
    queryFn: () =>
      projectService.projectListByUser({
        select: ['id', 'name', 'start_date', 'end_date', 'status'],
        pageVo: {
          pageNo: 1,
          noOfItems: 10,
        },
        user_id: user.id,
      }),
    select: (data) => {
      const response = data.data.rows;
      return response;
    },
    enabled: widget?.filters?.projectSelect && hasExistProject,
    throwOnError: (err) => {
      makeAlert(err.message, 'error');
    },
  });
  const handleWidget = (e) => {
    const { name, value } = e.target;
    if (name == 'size') {
      const position = widget?.position;
      setWidget((prev) => ({ ...prev, position: { ...position, w: parseInt(value) } }));
      return;
    }
    setWidget({ ...widget, [name]: value });
  };

  const handleWidgetSelect = (value) => {
    setWidget(value);
  };
  const handleProjectSelect = (value) => {
    setWidget((prev) => ({
      ...prev,
      project_id: value?.id,
    }));
  };

  const handleWidgetSubmit = () => {
    // if (widget?.filters?.projectSelect && !widget?.project_id) {
    //   makeAlert('Please select a project', 'error');
    //   return;
    // }
    // if (widget?.filters?.userSelect && !widget?.userType) {
    //   makeAlert('Please select a user type', 'error');
    //   return;
    // }
    if (!widget?.position || !widget?.title || !widget?.name) {
      makeAlert('Please fill in all fields', 'error');
      return;
    }
    onSubmit(widget);
  };

  if (widgetListLoading) {
    return (
      <div className='loading-wrapper'>
        <LoadingIcon color={'#c1c1c1'} size={70} />
      </div>
    );
  }
  if (!isOpen) return null;
  console.log({ widget });

  const GetWidgetFormatOptionLabel = ({ data, metadata }) => {
    return (
      <div className='widget-format-option-container'>
        <div className='widget-drop-icon-container'>{widgetIcons?.[data?.name]}</div>
        <div className='widget-format-option-label'>{data.title}</div>
      </div>
    );
  };

  return (
    <div className='widget-overlay'>
      <div className='h-90vh'>
        <h3 className='add-widget-heading'>{isEdit ? 'Update ' : 'Add '}Widget</h3>
        <div className='form-group mt-2'>
          <label className='widget-label'>Type</label>
          <SearchableQuerySelect
            queryFn={miscService.getAllWidgetPresets}
            initialValue={widgetList}
            getFunctionParams={(searchKey) => ({
              searchKey,
            })}
            isCustomFunction
            isLoading={widgetListLoading}
            queryKey='widgets-presets'
            formatOptionLabel={(data, metadata) => (
              <GetWidgetFormatOptionLabel data={data} metadata={metadata} />
            )}
            classNames={{ menuPortal: () => 'z-index-100 fz-14px react-select-portal' }}
            styles={CommonDropDownStyle}
            components={{ DropdownIndicator: CustomDropdownIndicator }}
            placeholder={`Choose `}
            onChange={(val) => {
              handleWidgetSelect(val);
            }}
            isDisabled={isEdit}
            value={[widget]}
            getOptionLabel={(option) => option?.label}
            getOptionValue={(option) => option?.name}
          />
        </div>
        <div className='form-group mt-2'>
          <label className='widget-label'>Columns</label>
          <div className='columns-options'>
            <button
              value={'1'}
              onClick={(e) => {
                handleWidget(e);
              }}
              name='size'
              className={widget?.position?.w == 1 ? 'active' : ''}
              disabled={!widget?.size?.includes(1)}
              style={{ cursor: widget?.size?.includes(1) ? 'pointer' : 'not-allowed' }}
            >
              1 columns
            </button>
            <button
              value={'2'}
              onClick={(e) => {
                handleWidget(e);
              }}
              name='size'
              className={widget?.position?.w == 2 ? 'active' : ''}
              disabled={!widget?.size?.includes(2)}
              style={{ cursor: widget?.size?.includes(2) ? 'pointer' : 'not-allowed' }}
            >
              2 columns
            </button>
            <button
              value={'3'}
              onClick={(e) => {
                handleWidget(e);
              }}
              name='size'
              className={widget?.position?.w == 3 ? 'active' : ''}
              disabled={!widget.size?.includes(3)}
              style={{ cursor: widget?.size?.includes(3) ? 'pointer' : 'not-allowed' }}
            >
              3 columns
            </button>
          </div>
        </div>
        {projectLoading && (
          <div className='loading-wrapper'>
            <LoadingIcon color={'#c1c1c1'} size={70} />
          </div>
        )}
        {hasExistProject && widget?.filters?.projectSelect && (
          <div className='form-group mt-2'>
            <label className='widget-label'>Project</label>
            <SearchableQuerySelect
              queryFn={projectService.projectListAdvanced}
              initialValue={projectData}
              getFunctionParams={(searchKey) => ({
                searchKey,
                select: ['id', 'name', 'start_date', 'end_date', 'status'],
                pageVo: {
                  pageNo: 1,
                  noOfItems: 10,
                },
              })}
              isCustomFunction
              isLoading={projectLoading}
              queryKey='projectList-widget'
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              classNames={{ menuPortal: () => 'z-index-100 fz-14px react-select-portal' }}
              styles={CommonDropDownStyle}
              components={{ DropdownIndicator: CustomDropdownIndicator }}
              placeholder={`Choose `}
              onChange={(val) => {
                handleProjectSelect(val);
              }}
            />
          </div>
        )}
        {widget?.filters?.userSelect && (
          <div className='form-group mt-2'>
            <label className='widget-label'>{widget?.title} Type</label>
            <div className='radio-group'>
              <label className='widget-label-radio'>
                <input type='radio' name='userType' value='personal' onChange={handleWidget} />{' '}
                Personal
              </label>
              <label className='widget-label-radio'>
                <input type='radio' name='userType' value='team' onChange={handleWidget} /> Team
              </label>
            </div>
          </div>
        )}
        {widget.name == 'calendar' && (
          <div className='form-group mt-2'>
            <label className='widget-label'>View types</label>
            <div className='columns-options'>
              <button
                value={'week'}
                onClick={(e) => {
                  handleWidget(e);
                }}
                name='viewType'
                className={widget?.viewType == 'week' ? 'active' : ''}
              >
                Week
              </button>
              <button
                value={'day'}
                onClick={(e) => {
                  handleWidget(e);
                }}
                name='viewType'
                className={widget?.viewType == 'day' ? 'active' : ''}
              >
                Day
              </button>
              <button
                value={'month'}
                onClick={(e) => {
                  handleWidget(e);
                }}
                name='viewType'
                className={widget?.viewType == 'month' ? 'active' : ''}
              >
                Month
              </button>
              <button
                value={'team'}
                onClick={(e) => {
                  handleWidget(e);
                }}
                name='viewType'
                className={widget?.viewType == 'team' ? 'active' : ''}
              >
                Team
              </button>
            </div>
          </div>
        )}
      </div>
      <div
        className={`mx-4 mb-3 mt-30px px-30px d-flex top-light-shadow justify-content-end align-items-center btn-container `}
      >
        <div className='d-flex gap-2'>
          <Button.Container className='cancel-btn' handleOk={onClose}>
            <Button.Title title='Cancel' />
          </Button.Container>
          <Button.Container type='submit' handleOk={handleWidgetSubmit}>
            <Button.Title title={'Save'} />
            <Button.Loading isLoading={isSaving} color='#FFF' size={20} />
          </Button.Container>
        </div>
      </div>
    </div>
  );
};

export default AddWidgetModal;
